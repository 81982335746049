<div class="main-div" [ngClass]="{'blur-background' : displayStyle === 'block'}">
  <div class="">
    <div class="mobile-heading">
      <h3 class="fw-bold mb-0">Company List</h3>
    </div>
    <div class="top-nav">
      <div class="desktop-heading">
        <h3 class="fw-bold">Company List</h3>
      </div>
      <div></div>
      <div class="top-nav-menu-item-container">

        <div class="top-nav-menu-item">
          <img src="assets/images/avatar.jpg" alt="">
          <span (click)="openSubscriptionDetailsModal()">Profile</span>
        </div>
        <div class="top-nav-menu-item">
          <i class="fas fa-money-bill-wave-alt"></i>
          <span (click)="redirectToPayment()">Payment</span>
        </div>
        <div class="top-nav-menu-item">
          <i class="fas fa-plus"></i>
          <span (click)="redirectToAddCompany()">New</span>
        </div>

        <div class="top-nav-menu-item">
          <i class="fas fa-sign-out-alt"></i>
          <span (click)="logout()">Logout</span>
        </div>

      </div>
    </div>


    <form formGroup="searchGroup" autocomplete="off">
      <div class="col-12 col-sm-5 col-lg-4 row search_filter ms-2 ms-sm-3 mt-3 mb-3" >
        <input class="pt-1 pb-1" placeholder="search company" (keyup)="applyFilter($event)" formControlName="search"/>
      </div>
    </form>
  </div>


  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="srno" matSortDirection="asc">
      <!-- srno Column -->
      <ng-container matColumnDef="srNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="">Sr. No.</th>
        <td mat-cell *matCellDef="let element" class="">
          <span class="col-12 d-flex">
            {{element.srNo}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="">Name</th>
        <td mat-cell *matCellDef="let element" class="">
          <span class="col-12 d-flex">{{element.name}} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="">Code</th>
        <td mat-cell *matCellDef="let element" class="">
          <span class="col-12 d-flex">COMP-{{element.companyId}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="">Actions</th>
        <td mat-cell *matCellDef="let row" class="justify-content-between">


          <i class="fas fa-sign-in-alt fs-6" (click)="loginCompany(row)"
            style="color:var(--oranges--orange-600);"></i>

          <i class="fas fa-trash ps-1 fs-6" (click)="getCompany(row)"
            style="color:var(--oranges--orange-600);" aria-label="delete"></i>


        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow style="display: center;">
        <td class="mat-cell mb-4" [attr.colspan]="displayedColumns.length">
          No data matching the filter.
        </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>

<!--Delete Company Modal -->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Delete Company</h4>
        <button type="button" class="btn-close" (click)="closepopup()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="deleteForm">
          <div class="form-check form-check-inline mb-3">
            <input class="form-check-input" type="radio" name="deleteCompanyFY" id="inlineRadio1"
            value="company" formControlName="deleteCompanyFY" (change)="onChangeDeleteOption()">
            <label class="form-check-label" for="inlineRadio1">Delete Company</label>
          </div>
          <div class="form-check form-check-inline mb-3">
            <input class="form-check-input" type="radio" name="deleteCompanyFY" id="inlineRadio2"
            value="financialYear" formControlName="deleteCompanyFY" (change)="onChangeDeleteOption()">
            <label class="form-check-label" for="inlineRadio2">Delete Financial Year</label>
          </div>
          <div class="row" *ngIf="showFinancialYear">
            <label class="col-4 ms-2">Financial Year:</label>
            <div class="col-6">
              <ng-select class="select-item mb-3" tabindex="2" formControlName="financialYear"
                placeholder="Select Financial Year...">
                <ng-option [value]="financialYear.value" *ngFor="let financialYear of financialyears">
                  {{financialYear.viewValue}}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button mat-raised-button color="warn" (click)="deleteCompany()">Delete</button>
        <button mat-raised-button data-bs-dismiss="modal" (click)="closepopup()">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" [ngStyle]="{ display: displaySubscriptionModal}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Complete Subscription Details</h2>
        <button type="button" class="btn-close" aria-label="Close" *ngIf="showModalClose" (click)="closeSubscriptionDetailsModal()"></button>
      </div>
      <div class="modal-body">
          <div class="row ms-sm-1 p-3 pt-0" [formGroup]="subscriptionDetailsFormGroup">
            <div class="col-12">
              <label class="col-8 col-form-label">Mobile Number</label>
              <div class="col-12">
                  <input type="text" formControlName="MobileNumber" class="rounded border border-1 col-12" (blur)="sendOTP()" />
                  <div *ngIf="isOTPSent" class="success-message">
                    OTP sent!
                </div>
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">OTP</label>
              <div class="col-12">
                  <input type="number" formControlName="OTP" class="rounded border border-1 col-12" />
                  <div *ngIf="!isOTPValid" class="error-message">
                    Invalid OTP!
                </div>
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">Address</label>
              <div class="col-12">
                  <input type="text" formControlName="Address" class="rounded border border-1 col-12" />
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">State</label>
              <div class="col-12">
                  <input type="text" formControlName="State" class="rounded border border-1 col-12"/>
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">City</label>
              <div class="col-12">
                  <input type="text" formControlName="City" class="rounded border border-1 col-12" />
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">Pin code</label>
              <div class="col-12">
                  <input type="text" formControlName="Pincode" class="rounded border border-1 col-12" />
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">GST Number</label>
              <div class="col-12">
                  <input type="text" formControlName="GstNo" class="rounded border border-1 col-12" />
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">Business Type</label>
              <div class="col-12">
                  <ng-select class="select-item rounded border border-1 col-12" formControlName="BusinessType">
                    <ng-option value="General">General</ng-option>
                    <ng-option value="Hotel">Hotel</ng-option>
                  </ng-select>
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">Password</label>
              <div class="col-12">
                  <input [type]="hide ? 'password' : 'text'" formControlName="Password" class="rounded border border-1 col-12" />
                  <a (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide" class="col-1 ps-4 ps-lg-1">
                    <i class="fas fa-eye col-12" [style.color]="hide ? 'var(--blacks--black-100)' : 'var(--oranges--orange-600)'"></i>
                    </a>
              </div>
            </div>
            <div class="col-12">
              <label class="col-8 col-form-label">Confirm Password</label>
              <div class="col-12">
                  <input [type]="hide ? 'password' : 'text'" formControlName="ConfirmPassword" class="rounded border border-1 col-12" />
                  <a (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide" class="col-1 ps-4 ps-lg-1">
                    <i class="fas fa-eye col-12" [style.color]="hide ? 'var(--blacks--black-100)' : 'var(--oranges--orange-600)'"></i>
                    </a>
              </div>
            </div>
          </div>
          <span *ngIf="isErrorMessage" style="color: red;">**Please fill all the fields.</span>
          <span *ngIf="isPasswordMatchError" style="color: red;">**Password & Confirm Password doesn't match!</span>
      </div>
      <div class="modal-footer">
        <div class="buttons-container">
            <!-- <button type="button" class="secondary-button" data-bs-dismiss="modal">Close</button> -->
            <button type="button" class="primary-button" (click)="UpdateSubscriberDetails()">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</div>
