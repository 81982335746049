<div id="bars-external" [ngClass]="{'navbar-open': isNavbarOpen}">
    <i class="fas fa-bars" (click)="toggleNavbar()"></i>
</div>

<div class="navbar row col-8 col-sm-6 col-md-4 col-lg-12 justify-content-left align-content-start align-items-lg-center ms-0 me-0" [ngClass]="{'navbar-open': isNavbarOpen}">

    <div class="nav-header row col-12 col-lg-2 ms-0 align-items-center pt-4 pt-lg-0">
        <div id="bars" class="ms-3 ps-md-2 col-2 col-md-2 d-lg-none" [ngClass]="{'navbar-open': isNavbarOpen}">
            <i class="fas fa-bars" (click)="toggleNavbar()"></i>
        </div>
        <!-- <img src="../../assets/images/logo/logo.png" alt="" id="logo" class="col-8 col-md-8 " [ngClass]="{'navbar-open': isNavbarOpen}"> -->
        <div id="logo" class="row col-12 col-xl-11 col-xxl-10 pt-4 pt-lg-0">
            <img (click)="redirectTo('')" src="assets/images/logo.png" alt="" id="logo" class="col-8 col-lg-12 align-self-left"
            [ngClass]="{'navbar-open': isNavbarOpen}">
        </div>
    </div>

    <div class="login-signup row col-11 col-lg-4 order-lg-3 ms-3 ms-lg-0 me-lg-0 pt-3 pt-lg-1">
        <a class="col-12 col-lg-5 me-lg-1 text-center pt-1 pb-1 mb-3 fw-bold" id="login-button" (click)="redirectTo('subscriber-login')">Login</a>
        <a class="col-12 col-lg-5 text-center pt-1 pb-1 fw-bold" id="signup-button" (click)="redirectTo('subscriber-signup')">Signup</a>
    </div>

    <ul class="nav-list row col-12 col-lg-6 col-xl-4 ms-3 pt-4 pt-lg-1">
        <li class="col-11 col-lg-2 mt-0 mb-0 pt-1 pb-1 rounded text-lg-center">
            <span class="fw-bold" (click)="redirectTo('')">Home</span>
        </li>
        <li class="col-11 col-lg-3 mt-0 mb-0 pt-1 pb-1 rounded text-lg-center">
            <span class="fw-bold" (click)="redirectTo('about-us')">About Us</span>
        </li>
        <li class="col-11 col-lg-3 mt-0 mb-0 pt-1 pb-1 rounded text-lg-center">
            <span class="fw-bold" (click)="redirectTo('pricing')">Pricing</span>
        </li>
        <li class="col-11 col-lg-4 mt-0 mb-0 pt-1 pb-1 rounded text-lg-center">
            <span class="fw-bold" (click)="redirectTo('contact-us')">Contact Us</span>
        </li>
    </ul>
</div>
