import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  private hubConnection: HubConnection;

  private ledgerUpdateSubject = new BehaviorSubject<string>(null);
  private itemUpdateSubject = new BehaviorSubject<string>(null);
  private itemCompanyLedgerUpdateSubject = new BehaviorSubject<string>(null);

  ledgerUpdates$ = this.ledgerUpdateSubject.asObservable();
  itemUpdates$ = this.itemUpdateSubject.asObservable();
  itemCompanyLedgerUpdates$ = this.itemCompanyLedgerUpdateSubject.asObservable();

  constructor() { }

  public startConnection(companyId: string) {
    let token = sessionStorage.getItem('ctoken')
    this.hubConnection = new HubConnectionBuilder().withUrl(`${environment.ASquareAPIURL}appHub`, {
      accessTokenFactory: () => token
    }).withAutomaticReconnect().build();

    this.hubConnection.start().then(() => {
      console.log('SignalR Connected');
      this.hubConnection.invoke('JoinCompanyGroup', companyId);
    }).catch((err) => console.log('Error while starting SignalR connection: ' + err));

    // Listen for ledger updates
    this.hubConnection.on('ReceiveLedgerUpdate', (message: string) => {
      this.ledgerUpdateSubject.next(message);
    });

    // Listen for item updates
    this.hubConnection.on('ReceiveItemUpdate', (message: string) => {
      this.itemUpdateSubject.next(message);
    });

    // Listen for item Company Ledger updates
    this.hubConnection.on('ReceiveItemCompanyLedgerUpdate', (message: string) => {
      this.itemCompanyLedgerUpdateSubject.next(message);
    });
  }

  public stopConnection() {
    this.hubConnection?.stop().then(() => console.log('SignalR Disconnected'));
  }
}
