import { Component, OnInit } from '@angular/core';

declare var gtag: Function;

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    if (typeof gtag === 'function') {
      gtag('event', 'conversion', {
        send_to: 'AW-16712726220/v7XDCOKs2tUZEMztn6E-',
        value: 1.0,
        currency: 'INR'
      });
    } else {
      console.warn('Google Analytics not loaded yet.');
    }
  }

}
