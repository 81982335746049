import { Component, OnInit, Inject } from '@angular/core';
import Typewriter from 't-writer.js';
import { DOCUMENT } from '@angular/common';
import Swiper from 'swiper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,private router: Router) { }

  ngOnInit(): void {
    const swiper = new Swiper('.testimonial-cards', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      
      pagination: {
        el: '.swiper-pagination',
      },
      loop: true,
    });

    const target = document.querySelector('.hero-span-element');

    const writer = new Typewriter(target, {
      loop: true,
      typeSpeed: 120,
      deleteSpeed: 120,
      typeColor: '#EF7F1A',
    })

    writer
      .type('Whole Sale')
      .rest(500)
      .changeOps({ deleteSpeed: 120 })
      .remove(10)
      .type('Retail')
      .rest(500)
      .remove(6)
      .type('Manufacturing')
      .rest(500)
      .changeOps({ deleteSpeed: 20 })
      .remove(13)
      .type('Restaurant')
      .rest(500)
      .changeOps({ deleteSpeed: 20 })
      .remove(10)
      .clear()
      .start()
  }
  
  redirectTo(page){
    this.router.navigate([page]);
  }
  
}
