import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LedgerService } from "src/app/services/ledger.service";
import * as LedgerActions from './ledger.actions';
import { catchError, map, mergeMap, of } from "rxjs";

@Injectable()
export class LedgerEffects {
    loadLedgers$ = createEffect(() => 
        this.actions$.pipe(
            ofType(LedgerActions.loadLedgers),
            mergeMap(() =>
                this.ledgerService.GetAllParties().pipe(
                    map(ledgers => LedgerActions.loadLedgersSuccess({ ledgers })),
                    catchError(error => of(LedgerActions.loadLedgersFailure({ error: error.message })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private ledgerService: LedgerService
    ) {}
}