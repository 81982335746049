import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  constructor(private router: Router, private http: HttpClient) { }


  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  GetAllEnquiry(): Observable<any> {
    const body = new HttpParams({ });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Enquiry/GetAllEnquiries`, body, options);
  }

  GetEnquiry(enquiry_id:any): Observable<any> {
    const body = new HttpParams({fromObject:enquiry_id});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Enquiry/GetEnquiryByID`, body, options);
  }

  SendOTP(enquiryData:any): Observable<any> {
    const body = new HttpParams({fromObject: enquiryData});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Enquiry/SendOTP`, body, options);
  }

  VerifyOTP(enquiryData:any): Observable<any> {
    const body = new HttpParams({fromObject: enquiryData});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Enquiry/VerifyOTP`, body, options);
  }

  InsertEnquiry(enquiryData:any): Observable<any> {
    const body = new HttpParams({fromObject: enquiryData});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Enquiry/InsertEnquiry`, body, options);
  }

  UpdateEnquiry(enquiry_id: any): Observable<any> {
    const body = new HttpParams({ fromObject: enquiry_id });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Enquiry/UpdateEnquiry`, body, options);
  }


  DeleteEnquiry(enquiry_id:any): Observable<any> {
    const body = new HttpParams({ fromObject: enquiry_id });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Enquiry/DeleteEnquiry`, body, options);
  }
}
