<!-- <app-web-navbar></app-web-navbar> -->
<div class="main-container">
  <!-- Hero Section -->
  <section class="hero">
    <div class="hero-content">
      <h1><span class="light-text">Transform Your Business  with </span> A-Square ERP Software</h1>
      <p>Streamline operations, boost productivity &amp; drive growth with our all in one ERP software</p>
      <button (click)="scrollToForm()" class="primary-button">Get a Free Demo</button>
    </div>
    <div class="hero-image">
      <img src="../../../../../assets/images/enquiry/businessman.webp" alt="Mockup displaying A-Square ERP software on a laptop, tablet, and smartphone, illustrating its versatility and user-friendly interface.">
    </div>
  </section>

  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': isPopupFormOpen ? 'block' : 'none'}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
          <section class="enquiry-form-container" #enquiryForm>
            <form [formGroup]="demoForm" (ngSubmit)="onSubmit()" class="enquiry-form">
              <h2>Request a Free Demo</h2>
              <div class="form-group">
                <input formControlName="name" type="text" placeholder="Full Name*">
                <div *ngIf="demoForm.get('name')?.invalid && demoForm.get('name')?.touched" class="error-message">
                  Name is required
                </div>
              </div>
              
              <div class="form-group">
                <input formControlName="phone" type="tel" placeholder="Phone Number*" (blur)="verifyMobileNumber()">
                <div *ngIf="demoForm.get('phone')?.invalid && demoForm.get('phone')?.touched" class="error-message">
                  Valid phone number is required
                </div>
              </div>
              <div class="field-set" *ngIf="isOTPSent && !isOTPVerified">
                <label for="otp">Enter OTP*</label>
                <input type="number" name="" id="" formControlName="OTP" >
              </div>
              <div class="form-group">
                <input formControlName="email" type="email" placeholder="Email Address*">
                <div *ngIf="demoForm.get('email')?.invalid && demoForm.get('email')?.touched" class="error-message">
                  Valid email is required
                </div>
              </div>
              <div class="form-group">
                <input formControlName="company" type="text" placeholder="Company Name">
              </div>
              <button type="button" (click)="sendOTP()" *ngIf="!isOTPSent && isMobileVerified">Send OTP</button>
              <button type="button" (click)="verifyOTP()" *ngIf="isOTPSent && !isOTPVerified">Verify OTP</button>
              <button type="submit" class="primary-button" [disabled]="!isOTPVerified || demoForm.invalid">Request Demo</button>
            </form>
        
            <div class="img-container">
              <img src="" alt="">
            </div>
            
          </section>
        </div>
        <div class="modal-footer">
          <button type="button" class="secondary-button" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </div>

  

  <!-- Enquiry Form -->
  <section class="enquiry-form-container" #enquiryForm>
    <form [formGroup]="demoForm" (ngSubmit)="onSubmit()" class="enquiry-form">
      <h2>Request a Free Demo</h2>
      <div class="form-group">
        <input formControlName="name" type="text" placeholder="Full Name*">
        <div *ngIf="demoForm.get('name')?.invalid && demoForm.get('name')?.touched" class="error-message">
          Name is required
        </div>
      </div>
      
      <div class="form-group">
        <input formControlName="phone" type="tel" placeholder="Phone Number*" (blur)="verifyMobileNumber()">
        <div *ngIf="demoForm.get('phone')?.invalid && demoForm.get('phone')?.touched" class="error-message">
          Valid phone number is required
        </div>
      </div>
      <div class="field-set" *ngIf="isOTPSent && !isOTPVerified">
        <label for="otp">Enter OTP*</label>
        <input type="number" name="" id="" formControlName="OTP" >
      </div>
      <div class="form-group">
        <input formControlName="email" type="email" placeholder="Email Address*">
        <div *ngIf="demoForm.get('email')?.invalid && demoForm.get('email')?.touched" class="error-message">
          Valid email is required
        </div>
      </div>
      <div class="form-group">
        <input formControlName="company" type="text" placeholder="Company Name">
      </div>
      <button type="button" (click)="sendOTP()" *ngIf="!isOTPSent && isMobileVerified">Send OTP</button>
      <button type="button" (click)="verifyOTP()" *ngIf="isOTPSent && !isOTPVerified">Verify OTP</button>
      <button type="submit" class="primary-button" [disabled]="!isOTPVerified || demoForm.invalid">Request Demo</button>
    </form>

    <div class="slider-container">
      <div class="slider-heading">
        <p>Tailored ERP for efficiency.</p>
        <h2>Industries We Serve</h2>
      </div>
      <!-- Swiper Section -->
      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="slide-content">
              <i class="fas fa-store"></i>
              <span>Retail</span>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide-content">
              <i class="fas fa-industry"></i>
              <span>Manufacturing</span>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide-content">
              <i class="fas fa-utensils"></i>
              <span>Restaurant</span>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide-content">
              <i class="fas fa-box-open"></i>
              <span>Wholesale</span>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    
    
    <div class="waves-container"> 
    </div>
    
  </section>

  <!-- Feature Highlights -->
  <section class="feature-highlights">
    <div class="feature" *ngFor="let feature of features">
      <i class="{{feature.icon}}"></i>
      <h3>{{ feature.title }}</h3>
      <p>{{ feature.description }}</p>
      <div class="cta">
        <a class="cta-button" [routerLink]="[feature.ctaLink]">{{ feature.cta }}</a>
      </div>
    </div>
  </section>



  <!-- Client Logos -->
  <section class="client-logos">
    <h2><span class="light-text">Trusted by</span> Leading Businesses</h2>
    <div class="client-logos-slider" 
        (mouseenter)="pauseMarquee()" 
        (mouseleave)="resumeMarquee()">
      <div class="logo-container">
        <div class="" *ngFor="let client of clients">
          <img [src]="client.logo" [alt]="client.name">
        </div>
      </div>
    </div>
  </section>


  <section class="e-invoicing-section">
    <div class="e-invoicing-wrapper">
      <div class="e-invoicing-content">
        <div class="e-invoicing-text">
          <!-- <canvas class="background"></canvas> -->
          <h2>What is E-Invoicing? <span class="light-text">Why is it essential?</span></h2>
          <p>E-invoicing is also called electronic invoicing; as the name suggests, it creates an automated invoice for electronic transfer.</p>
          <p>As per the recent CGST Regulations, taxpayers have turnovers above 50 Cr. INR must generate an E-Invoice from 1 April 2021 directed by the Indian Government.</p>
          <p>E-invoicing helps to reduce the use of paper, as it is paperless. Thus, it helps to save nature by reducing the use of paper. Also, it does not require a workforce. Therefore, errors cannot be caused during the E-Invoice creation. Consequently, it maintains and improves timely payment and cash flow for the Suppliers.</p>
        </div>
        <div class="e-invoicing-image">
          <img src="../../../../../assets/images/enquiry/E-invoicing.webp" alt="E-Invoicing Image">
        </div>
      </div>
    </div>
  </section>
  
   <!-- New section for infinite Swiper slideshow -->
   <section class="infinite-swiper-section">
    <div class="swiper-container" id="infiniteSwiper2">
      <div class="swiper-wrapper" >
        <div class="swiper-slide">
          <div class="slide-content">
            <img src="../../../../../assets/images//enquiry/slider-icons/automated-serial-number.svg" alt="Icon" />
            <h3>Automated Serial Number</h3>
            <p class="marquee">A-Square ERP generates unique serial numbers, barcodes for each e-invoice, and assigns HSN codes (for turnover below 1.5 Cr) or SAC codes for service providers. It automates processes with IRP and GSTN integration.</p>
          </div>
        </div>
        
        <div class="swiper-slide">
          <div class="slide-content">
            <img src="../../../../../assets/images//enquiry/slider-icons/GST-Compliant.svg" alt="Icon" />
            <h3>GST Compliant</h3>
            <p class="marquee">All the E-Invoice generated is fully automated to fulfil GST Compliance as per Indian Government GST Regulations. Thus, the Tax filing process becomes smooth, completing the A part of E-Way bills.</p>
          </div>
        </div>
        
        <div class="swiper-slide">
          <div class="slide-content">
            <img src="../../../../../assets/images//enquiry/slider-icons/realtime-tracking.svg" alt="Icon" />
            <h3>Realtime Tracking</h3>
            <p class="marquee">A-Square ERP software does all the real-time tracking of e-invoices generated by suppliers. Hence, there are no chances of inaccuracy in the data, reducing the chances of data manipulation or errors.</p>
          </div>
        </div>
        
        <div class="swiper-slide">
          <div class="slide-content">
            <img src="../../../../../assets/images//enquiry/slider-icons/Digital-Signature.svg" alt="Icon" />
            <h3>Digital Signature</h3>
            <p class="marquee">A-Square ERP software implements an automatic digital signature on the E-Invoice. Thus, uploading an e-invoice cuts down the necessity of a physical signature. A-Square ERP takes care of it.</p>
          </div>
        </div>
        
        <div class="swiper-slide">
          <div class="slide-content">
            <img src="../../../../../assets/images//enquiry/slider-icons/Auto-Reconciliation.svg" alt="Icon" />
            <h3>Auto Reconciliation</h3>
            <p class="marquee">A-Square ERP automates e-invoicing by integrating with the GSTN portal, eliminating manual work. With one click, taxpayers can generate and submit e-invoices in real-time, ensuring compliance and efficiency.</p>
          </div>
        </div>
  
  
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </section>

  <section class="eway-billing-section">
    <div class="eway-billing-wrapper">
      <div class="eway-billing-content">
        <div class="eway-billing-text">
          <!-- <canvas class="background"></canvas> -->
          <canvas id="tsparticles" class="background"></canvas>
          <h2>What is E-Way Billing, & <span class="light-text">Why is it needed?</span></h2>
          <p>E-way billing is a procedure of uploading an e-way bill to transport goods from one place to another. As per GST Regulations led by the Indian Government, e-way bills must be raised if the transportation of Goods has a value exceeding 50,000 INR by a registered GST taxpayer or firm.</p>
          <p>After submitting the E-Way bill, an E-Way Number (EBN) is allocated to the Supplier, Recipient, and Transporter.</p>
          <p>A-Square ERP does E-Way billing seamlessly in an automated way. So you don’t have to worry about manually checking billing data for the E-Way billing procedure.</p>
        </div>
        <div class="eway-billing-image">
          <img src="../../../../../assets/images/enquiry/E-wayBill.webp" alt="E-Way Billing Image">
        </div>
      </div>
    </div>
  </section>



  <!-- New section for infinite Swiper slideshow -->
<section class="infinite-swiper-section">
  <div class="swiper-container" id="infiniteSwiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="slide-content">
          <img src="../../../../../assets/images//enquiry/slider-icons/data-privacy-.svg" alt="Icon" />
          <h3>Data Privacy</h3>
          <p>A-Square ERP ensures robust data privacy by safeguarding your sensitive data with advanced security measures. It offers full assurance that your data remains secure & protected across the cyber domain.</p>
        </div>
      </div>
      
      <div class="swiper-slide">
        <div class="slide-content">
          <img src="../../../../../assets/images//enquiry/slider-icons/dedicated-support.svg" alt="Icon" />
          <h3>Dedicated Support</h3>
          <p>Our dedicated support team is an expert in problem-solving and is available anytime to assist with your doubts or queries with automated e-way billing, ensuring prompt and reliable help when needed.</p>
        </div>
      </div>
      
      <div class="swiper-slide">
        <div class="slide-content">
          <img src="../../../../../assets/images//enquiry/slider-icons/Seamless-Integration.svg" alt="Icon" />
          <h3>Seamless Integration</h3>
          <p>A-Square ERP integrates automatically with the government portal for the e-way billing process. So, you don't need to worry, as our software's automated system handles everything error-free & effortlessly.</p>
        </div>
      </div>
      
      <div class="swiper-slide">
        <div class="slide-content">
          <img src="../../../../../assets/images//enquiry/slider-icons/data-validation.svg" alt="Icon" />
          <h3>Auto-validation of Data</h3>
          <p>A-Square ERP ensures automated data validation, eliminating the chance of errors. With built-in advanced validation processes, your data is checked and verified accurately without manual intervention.
          </p>
        </div>
      </div>
      
      <div class="swiper-slide">
        <div class="slide-content">
          <img src="../../../../../assets/images//enquiry/slider-icons/save-time-manpower.svg" alt="Icon" />
          <h3>Save Time & Manpower</h3>
          <p>Sometimes, Submitting e-way bills requires manual effort, but A-Square ERP automates the process. It reduces all the manual work, saving valuable time and workforce while streamlining your operations.</p>
        </div>
      </div>


    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</section>


<section class="two-column-section">
  <h2><span class="light-text">Our Integration</span> Partners</h2>
  <div class="two-column-grid">
    <div class="first-column">
      <!-- Content for the first column goes here -->
    </div>
    <div class="second-column">
      <!-- Swiper Vertical Slider -->
      <div class="swiper vertical-swiper" [style.height.px]="200">
        <div class="swiper-wrapper">

          <div class="swiper-slide">
            <div class="integration-partner">
              <img src="../../../../../assets/images/enquiry/integrationPartners/azure.png" alt="azure">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="integration-partner">
              <img src="../../../../../assets/images/enquiry/integrationPartners/gcp.png" alt="google cloud platform">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="integration-partner">
              <img src="../../../../../assets/images/enquiry/integrationPartners/razorpay.png" alt="razorpay">
            </div>
          </div>

          <div class="swiper-slide">
            <div class="integration-partner">
              <img src="../../../../../assets/images/enquiry/integrationPartners/tally.png" alt="tally">
            </div>
          </div>
          
          <!-- Add more swiper-slide divs as needed -->
        </div>
   
      </div>
    </div>
  </div>
</section>

  <!-- Detailed Features -->
  <section class="detailed-features">
    <h2><span class="light-text">Comprehensive</span> ERP<span class="light-text"> Solutions</span></h2>
    <div class="feature-grid">
      <div class="feature-card" *ngFor="let feature of detailedFeatures">
        <i class="{{ feature.icon }}"></i>
        <h3>{{ feature.title }}</h3>
        <p>{{ feature.description }}</p>
        <a (click)="showFeatureDetails(feature)" class="primary-button">{{ feature.callToAction }}</a>
      </div>
    </div>
  </section>

  
  <!-- Our Integration Partners -->
  <section class="sectors">
    <h2><span class="light-text">Sectors We Work In</span> with A-Square ERP Software</h2>
    <p>Explore the diverse sectors where A-Square ERP Software excels in delivering tailored solutions. With industry-specific expertise, we empower businesses to streamline operations, enhance efficiency, and achieve sustainable growth across various domains.</p>
    <div class="sectors-grid">
      <div class="swiper sectors-slider" >
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let ImgSrc of sectorsimg">
            <div class="sector-img">
              <img [src]="ImgSrc.imgSrc" [alt]="ImgSrc.alt" [attr.label]="ImgSrc.alt">
              <caption>{{ ImgSrc.label }}</caption>
            </div>
          </div>
        </div>
        <div class="swiper-buttons">
          <div class="swiper-button-next" >
            <i class="fas fa-chevron-right"></i>
          </div>
          <div class="swiper-button-prev" >
            <i class="fas fa-chevron-left"></i>
          </div>
        </div>

      </div>
    </div>
  </section>
  
  

  <section class="testimonials">
    <h2> <span class="light-text">What</span> Our Clients Say</h2>
    <div class="testimonial-slider swiper-container">
      <div class="swiper-wrapper">
        <div class="testimonial swiper-slide" *ngFor="let testimonial of testimonials">
          <div class="swiper-content">
            <p>"{{ testimonial.quote }}"</p>
            <div class="author">
              <img [src]="testimonial.avatar" [alt]="testimonial.name">
              <div>
                <h4>{{ testimonial.name }}</h4>
                <p>{{ testimonial.position }}, {{ testimonial.company }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
      <!-- Add Navigation -->
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </section>

  <!-- FAQ Section -->
  <!-- <section class="faq">
    <h2>Frequently Asked Questions</h2>
    <div class="faq-list">
      <div class="faq-item" *ngFor="let item of faqItems">
        <h3 (click)="toggleFaq(item)">{{ item.question }}</h3>
        <p [@faqAnimation]="item.isOpen ? 'open' : 'closed'">{{ item.answer }}</p>
      </div>
    </div>
  </section> -->
</div>

<app-web-footer></app-web-footer>
