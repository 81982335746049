import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  constructor(private router: Router, private http: HttpClient) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  GetItemGroups(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetItemGroups`, body, options);
  }

  GetAllLedgerForCompany(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllLedgerForCompany`, body, options);
  }

  GetAllTaxCategory(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllTaxCategory`, body, options);
  }

  GetAllUnitForCompany(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllUnitForCompany`, body, options);
  }

  GetAllItems(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllItems`, body, options);
  }

  GetAllItemsforCompany(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllItemsforCompany`, body, options);
  }

  GetAllItemsWithBarcode(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllItemsWithBarcode`, body, options);
  }

  GetAllItemsWithImage(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllItemsWithImage`, body, options);
  }

  GetAllBrands(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllBrands`, body, options);
  }

  GetAllVariants(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetAllVariants`, body, options);
  }

  InsertItem(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/InsertItem`, body, options);
  }

  InsertBrand(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/InsertBrand`, body, options);
  }

  InsertVariant(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/InsertVariant`, body, options);
  }

  DeleteItem(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/DeleteItem`, body, options);
  }

  DeleteBrand(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/DeleteBrand`, body, options);
  }

  DeleteVariant(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/DeleteVariant`, body, options);
  }

  GetItemByID(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetItemByID`, body, options);
  }

  UpdateItem(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/UpdateItem`, body, options);
  }

  UpdateBrand(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/UpdateBrand`, body, options);
  }

  UpdateVariant(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/UpdateVariant`, body, options);
  }

  GetItemTax(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Item/GetItemTax`, body, options);
  }
}
