<div class="page-heading">

    <div class="mobile-heading">
      <h3 class="fw-bold mb-0">{{companyName}}</h3>
    </div>
    <div class="top-nav">
      <div class="desktop-heading">
        <h3 class="fw-bold">{{companyName}}</h3>
      </div>
      <div></div>
      <div class="top-nav-menu-item-container">
        <div class="top-nav-menu-item">
          <i class="fas fa-check-circle"></i>
          <span (click)="selectCompany()">Select</span>
        </div>
        <div class="top-nav-menu-item">
          <i class="fas fa-sign-out-alt"></i>
          <span (click)="logout()">Logout</span>
        </div>
      </div>
    </div>
</div>
<div class="main-div row col-12 col-lg-8 mt-1 mb-4 ms-auto me-auto justify-content-center">

    <div class="user-login-header row col-12 col-lg-6 ms-0 d-none d-lg-flex order-2">
        <div class="stairs-top-left">
            <img src="assets/images/subscriber-login/stair-top-left.png" alt="">
        </div>

        <div class="user-login-content row col-12 mt-4 ">
            <div class="user-login-img mb-5 col-lg-7 ms-auto me-auto">
                <img src="assets/images/subscriber-login/subscriber-login-img.png" alt="">
            </div>
            <div class="user-login-description row col-12 col-lg-8 ms-auto me-auto mb-4">
                <h3 class="col-12 text-center">Take control of your business today!</h3>
                <p class="col-12 text-center"> Track new sales, invoices, and important transactions. Take control of
                    your business today!</p>
            </div>
        </div>

        <div class="stairs-bottom-right">
            <img src="assets/images/subscriber-login/stair-bottom-right.png" alt="">
        </div>
    </div>

    <div class="user-login row col-12 col-sm-8 col-md-6 col-lg-6 mt-4 mt-lg-0 me-lg-1 ps-2 pe-0 pt-sm-3 pb-sm-3 justify-content-center order-1">

        <form class="row col-12" [formGroup]="loginForm" (ngSubmit)="Userlogin()" *ngIf="loginForm">
            <div class="form-heading row col-12 ">
                <h3 class="col-12 fw-bold mb-2">User Login</h3>
                <p class="col-12 fw-bolder mb-0">See your growth and get sales reports</p>
            </div>

            <div class="row col-12 mt-4">
                <label class="col-12 col-lg-5 ms-0 mt-1 mb-2">Username</label>

                <mat-form-field appearance="outline">
                    <input matInput class="col-12" placeholder="Username" formControlName="Username" required>
                    <mat-error *ngIf="Username.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
                
                <label class="col-12 col-lg-5 ms-0 mt-3 mb-2">Password</label>
                <mat-form-field appearance="outline">
                    <input matInput class="col-10 ms-2" [type]="hide ? 'password' : 'text'" formControlName="Password">
                    <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon color="warn">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                </mat-form-field>
                

                

                <label class="col-12 col-lg-5 ms-0 mt-3 mb-2" *ngIf="deleteMode === 'financialYear' || !isDelete">Financial Year</label>
                <mat-select class="col-10 ms-2 mb-3" formControlName="FinancialYear" name="year" [required]="deleteMode === 'financialYear' || !isDelete"
                *ngIf="deleteMode === 'financialYear' || !isDelete">
                  <mat-option *ngFor="let financialYear of financialyears" [value]="financialYear.value">
                    {{financialYear.viewValue}}
                  </mat-option>
                </mat-select>

                  
                  <button class="primary-button" type="submit" *ngIf="isDelete === false">Login</button>
                  <p style="text-align: center;margin-top: 4px;cursor: pointer;" *ngIf="isDelete === false">
                    <span style="color: red;">{{ErrorMessage}}</span>
                  </p>
                
                  <button class="btn btn-sm btn-danger" type="submit" *ngIf="isDelete">Delete</button>
                  <p style="text-align: center;margin-top: 4px;cursor: pointer;" *ngIf="isDelete">
                    <span style="color: red;">{{ErrorMessage}}</span>
                  </p>
                  <p class="col-12 fw-bolder text-end" style="color:var(--oranges--orange-600);">
                    <a (click)="redirectToForgotPassword()">Forgot Password ?</a>
                  </p>

                
                
            </div>
        </form>
    </div>
</div>
<div class="modal" tabindex="-1" [ngStyle]="{'display':displayConfirmDelete}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirm Delete</h4>
          <button type="button" class="btn-close"  (click)="closeConfirm()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Are you sure want to Delete?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="confirmDelete()">Confirm</button>
          <button type="button" class="btn btn-outline-secondary "(click)="closeConfirm()">Cancel</button>
        </div>
      </div>
    </div>
  </div>