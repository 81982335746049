import { AuthService } from './../../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isPlatform, Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router, private cookieService: CookieService) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let isLoggedIn = false;
    let userType: 'subscriber' | 'companyUser' | 'blogUser' | null = null;
    let subscriberId = '';

    if (isPlatform('capacitor')) {
      const subscriber = await this.authService.getPreferenceSubscriberDetails();
      isLoggedIn = !!subscriber;
      if (subscriber) {
        userType = 'subscriber';
        subscriberId = subscriber.subscriberID?.toString() || '';
      }
    } else {
      const subscriberString = sessionStorage.getItem('subscriber') || this.cookieService.get('subscriber');
      const blogUserString = sessionStorage.getItem('blogUser');
      const companyUserString = sessionStorage.getItem('companyUser');

      if (subscriberString) {
        isLoggedIn = true;
        userType = 'subscriber';
        subscriberId = sessionStorage.getItem('subscriberID') || this.cookieService.get('subscriberID');
      } else if (blogUserString) {
        isLoggedIn = true;
        userType = 'blogUser';
      } else if (companyUserString) {
        isLoggedIn = true;
        userType = 'companyUser';
      }
    }

    // Handle root route (landing page)
    if (state.url === '/') {
      if (isLoggedIn) {
        if (userType === 'subscriber') {
          await this.router.navigate(['view-company'], { queryParams: { subscriberID: subscriberId } });
          return false; // Prevent further route activation
        } else if (userType === 'companyUser') {
          await this.router.navigate(['admin/home']);
          return false;
        } else if (userType === 'blogUser') {
          await this.router.navigate(['view-blog']);
          return false;
        }
      }
      return true; // Allow access to the landing page
    }

    // Handle other routes (protected)
    if (isLoggedIn) {
      return true; // Allow access to protected routes
    } else {
      await this.router.navigate(['subscriber-login']);
      return false; // Redirect to login for unauthenticated users
    }
  }
}
