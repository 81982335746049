import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnquiryService } from 'src/app/services/enquiry.service';
import Swiper, { Navigation } from 'swiper';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

Swiper.use([Navigation]);

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css'],
  animations: [
    trigger('faqAnimation', [
      state('closed', style({ height: '0px', opacity: 0 })),
      state('open', style({ height: '*', opacity: 1 })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class EnquiryComponent implements OnInit, AfterViewInit {
  @ViewChild('enquiryForm') enquiryForm!: ElementRef;
  // @ViewChild('backgroundCanvas', { static: false }) backgroundCanvas: ElementRef;

  enquiryform!: FormGroup;
  demoForm!: FormGroup;
  isOTPSent: boolean = false;
  isOTPValid: boolean | null = null;
  isMobileVerified: boolean = false;
  isMarqueePaused: boolean = false;
  isPopupFormOpen: boolean = false;


  slides = [
    { image: 'https://logoipsum.com/artwork/330' },
    { image: 'path/to/slide2.jpg' },
    { image: 'path/to/slide3.jpg' }
  ];

  features = [
    { icon: 'fas fa-warehouse', title: 'Inventory Management', description: 'Track and manage your stock levels efficiently, with automated inventory tracking and low stock alerts. Manage stock levels, track orders, and optimize inventory.', cta: 'Discover More', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-cash-register', title: 'Point of Sale', description: 'Streamline your sales process with our intuitive POS system, including inventory management and sales tracking. Manage customer interactions and process payments.', cta: 'Explore POS', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-chart-line', title: 'Advanced Reporting', description: 'Gain valuable insights with comprehensive analytics and reporting tools, including sales reports and customer analytics. Analyze sales trends and track customer behavior.', cta: 'Analyze Data', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-users', title: 'GST Reporting', description: 'Our ERP software provides detailed GST taxation reports, offering a comprehensive overview for accurate and efficient tax management.', cta: 'Start Managing', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-truck', title: 'Supply Chain Management', description: 'Optimize your supply chain for better efficiency with our supply chain management system, including procurement and inventory management. Manage your supply chain.', cta: 'Supply Chain', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-file-invoice-dollar', title: 'Financial Management', description: 'Comprehensive tools for accounting and budgeting, including invoicing, payment tracking, and expense management. Manage your finances and track expenses.', cta: 'Financial Clarity', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-cogs', title: 'Bill of Material (BOM) & Production ', description: 'Now you can easily create work and production orders, manage BOM, including design and assembly, and improve quality with our ERP solution.', cta: 'Automate Tasks', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-shield-alt', title: 'Data Security', description: 'Protect your data with advanced security measures, including data encryption, secure logins, and access controls. Keep your data safe and secure.', cta: 'Secure Data', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-mobile-alt', title: 'Mobile Access', description: 'Access your ERP system on the go with mobile compatibility, including mobile apps and responsive design. Access your data on the go.', cta: 'Mobile App', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-chart-pie', title: 'Business Intelligence', description: 'Leverage data analytics for informed decision-making with our business intelligence system, including data visualization and reporting tools.', cta: 'Custom Reports', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-bolt', title: 'Real-Time Analytics', description: 'Get instant insights into your business performance with real-time analytics and reporting tools, including sales tracking and customer analytics. Track your sales.', cta: 'Get Insights', link: '/subscriber-signup', height: '120px' },
    { icon: 'fas fa-sync-alt', title: 'Seamless Integration', description: 'Our ERP software easily integrates with other tools like Tally ERP and Tally Prime.', cta: 'Integrate Now', link: '/subscriber-signup', height: '120px' }
  ];

  clients = [
    { name: 'Balwant Collection', logo: '../../../../../assets/images/enquiry/client-logos/Balwwant-Collection.avif' },
    { name: 'Shree Electronics', logo: '../../../../../assets/images/enquiry/client-logos/Shree-Electronics.avif' },
    { name: 'IDM Industries', logo: '../../../../../assets/images/enquiry/client-logos/IDM-Industries.avif' },
    { name: 'Krishna Textiles', logo: '../../../../../assets/images/enquiry/client-logos/Krishna-Textiles.avif' },
    { name: 'Mauli Chemicals', logo: '../../../../../assets/images/enquiry/client-logos/Mauli-Chemicals.avif' },
    { name: 'Sai Transtech', logo: '../../../../../assets/images/enquiry/client-logos/Sai-Transtech-logo.avif' },
    { name: 'Shehnai', logo: '../../../../../assets/images/enquiry/client-logos/Shehnai.avif' },
    { name: 'VED ENTERPRISES', logo: '../../../../../assets/images/enquiry/client-logos/VED-ENTERPRISES.avif' },
    { name: 'Shri Sai', logo: '../../../../../assets/images/enquiry/client-logos/SHREE SAI.avif' },
    { name: 'Shree Swami Enterprises', logo: '../../../../../assets/images/enquiry/client-logos/SHREE SWAMI.avif' },
    { name: 'Gajanan workers', logo: '../../../../../assets/images/enquiry/client-logos/GAJANAN.avif' },
  ];



  testimonials = [
    {
      quote: "The software is very reasonable with all the necessary features in it. Service is very prompt. Till date they have provided me with all the required customisations unlike other companies.",
      name: "Shreeji Electronics",
      company: "Sinnar",
      avatar: ""
    },
    {
      quote: "Whenever we call the company for any support we have been fully satisfied. Staff is very soft spoken. One of the features that I like about this software is its multiple billing window.",
      name: "Kavya Collection",
      company: "Nasik",
      avatar: ""
    },
    {
      quote: "I was finding software which will suit the seeds of my dairy shop. Finally I came across A-Square software. They provided me the software will all the necessary customisations which are required in dairy business which are hard to find in other software.",
      name: "Rane Dairy",
      company: "Nasik",
      avatar: ""
    },
    {
      quote: "I had used many billing Softwares for my retail shop but my father was not happy. So I decided to buy a new one which will solve my business needs. This is when I came to know about A-Square ERP. Very detailed software at a very cheap cost",
      name: "Chandwadkar Garments",
      company: "Nasik",
      avatar: ""
    },
    {
      quote: "This is a very easy software to use will all the features. I always recommend my friends to use this software whenever they ask me for suggestion.",
      name: "Tara Telecom",
      company: "Ozar",
      avatar: ""
    }
  ];

  faqItems = [
    { question: 'What industries does A-Square ERP cater to?', answer: 'A-Square ERP is versatile and caters to various industries including manufacturing, retail, healthcare, and more. Our solutions are customizable to meet specific industry needs.', isOpen: false },
    { question: 'How long does implementation typically take?', answer: 'Implementation time varies depending on the complexity of your business and the modules you choose. On average, it takes 2-4 months, but we work closely with you to ensure a smooth and efficient process.', isOpen: false },
    // Add more FAQ items
  ];

sectorsimg = [
  { imgSrc: '../../../../../assets/images/enquiry/sectors/auto-accessories.avif', alt: 'auto-accessories', label: 'Auto Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/auto-parts-store.avif', alt: 'auto-parts-store', label: 'Auto Parts Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/Book-store.avif', alt: 'Book-store', label: 'Book Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/boutiques.avif', alt: 'boutiques', label: 'Boutiques' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/camera-and-accessories-shop.avif', alt: 'camera-and-accessories-shop', label: 'Camera & Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/car-accessories-store.avif', alt: 'car-accessories-store', label: 'Car Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/clothing-store.avif', alt: 'clothing-store', label: 'Clothing Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/coffee-shop.avif', alt: 'coffee-shop', label: 'Coffee Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/computer-hardware-and-accessories-store.avif', alt: 'computer-hardware-and-accessories-store', label: 'Computer Hardware' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/convenience-store.avif', alt: 'convenience-store', label: 'Convenience Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/cosmetics-store.avif', alt: 'cosmetics-store', label: 'Cosmetics Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/departmental-store.avif', alt: 'departmental-store', label: 'Departmental Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/electronics-store.avif', alt: 'electronics-store', label: 'Electronics Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/fashion-accessories.avif', alt: 'fashion-accessories', label: 'Fashion Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/Footwear-Store.avif', alt: 'Footwear-Store', label: 'Footwear Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/furniture-mall.avif', alt: 'furniture-Store', label: 'Furniture Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/furnituremall.avif', alt: 'Furniture-Mall', label: 'Furniture Mall' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/gift-shop.avif', alt: 'gift-shop', label: 'Gift Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/grocery-store.avif', alt: 'grocery-store', label: 'Grocery Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/gym-equipments.avif', alt: 'gym-equipments', label: 'Gym Equipments' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/hardware-shop.avif', alt: 'hardware-shop', label: 'Hardware Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/hardware-store.avif', alt: 'hardware-store', label: 'Hardware Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/home-and-kitchen-appliancess.avif', alt: 'home-and-kitchen-appliancess', label: 'Home & Kitchen' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/home-decor.avif', alt: 'home-decor', label: 'Home Decor' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/Household-Products-Store.avif', alt: 'Household-Products-Store', label: 'Household Products' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/imitation-jewellery-shop.avif', alt: 'imitation-jewellery-shop', label: 'Imitation Jewellery' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/indianrestaurant.avif', alt: 'Indian-Restaurant', label: 'Indian Restaurant' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/kitchen-utensils.avif', alt: 'kitchen-utensils', label: 'Kitchen Utensils' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/Kirana-Shop.avif', alt: 'Kirana-Shop', label: 'Kirana Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/luggage-and-bags.avif', alt: 'luggage-and-bags', label: 'Luggage & Bags' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/manufacturing.avif', alt: 'Manufacturing', label: 'Manufacturing' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/mobile-shop.avif', alt: 'mobile-shop', label: 'Mobile Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/mobile-store.avif', alt: 'mobile-store', label: 'Mobile Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/optical-store.avif', alt: 'optical-store', label: 'Optical Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/pet-store.avif', alt: 'pet-store', label: 'Pet Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/plastic-store.avif', alt: 'plastic-store', label: 'Plastic Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/readymadestore.avif', alt: 'Readymade-Store-image', label: 'Readymade Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/sports-and-gym-equipment-store.avif', alt: 'sports-and-gym-equipment-store', label: 'Sports & Gym' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/stationery-store.avif', alt: 'stationery-store', label: 'Stationery Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/sweet-shop.avif', alt: 'sweet-shop', label: 'Sweet Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/watch-and-accesories.avif', alt: 'watch-and-accesories', label: 'Watch & Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/indiancafe.avif', alt: 'Indian-Cafe', label: 'Indian Cafe' },
];

  constructor(private enquiryService: EnquiryService, private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeDemoForm();
  }

  ngAfterViewInit(): void {
    const enquirySwiper = new Swiper('.mySwiper', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      rewind: true,
      loop: true,
      autoplay: {
        delay: 800,
      },
    });

    const infiniteSwiper = new Swiper('#infiniteSwiper', {
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      rewind: true,
      loop: true,
      autoplay: {
        delay: 800,
      },
    });

    const infiniteSwiper2 = new Swiper('#infiniteSwiper2', {
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      rewind: true,
      loop: true,
      autoplay: {
        delay: 800,
      },
    });

    const testimonialSwiper = new Swiper('.testimonial-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      loop: true,
      autoplay: {
        delay: 800,
      },
      rewind: true,
    });

    // const verticalSwiper = new Swiper('.vertical-swiper', {
    //   direction: 'vertical',
    //   loop: true,
    //   autoplay: {
    //     delay: 800,
    //   },
    // });

    let sectorsSwiper = new Swiper('.sectors-slider', {
      slidesPerView: 1,
      spaceBetween: 64,
      loop: true,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    Swiper.use([Navigation]);

    let timeoutId: any;
    let isScrolling = false;
    window.addEventListener("scroll", () => {
      clearTimeout(timeoutId);
      isScrolling = true;
      this.isPopupFormOpen = false;
    });
    window.addEventListener("scrollend", () => {
      isScrolling = false;
    });
    window.addEventListener("scrollstop", () => {
      isScrolling = false;
    });
    timeoutId = setTimeout(() => {
      if(!isScrolling) {
        this.isPopupFormOpen = true;
      }
    }, 180000); // 3 minutes

  }

  @ViewChild('mainDiv') mainDiv: ElementRef;

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  //   const windowHeight = window.innerHeight;
  //   const documentHeight = document.documentElement.scrollHeight;

  //   // Calculate the scroll position as a percentage
  //   const scrollPercentage = (scrollTop + windowHeight) / documentHeight;

  //   // Trigger modal when the scroll position reaches or exceeds 50%
  //   if (scrollPercentage >= 0.5 && !this.isPopupFormOpen) {
  //     this.isPopupFormOpen = true;
  //   }
  // }


  closeModal() {
    this.isPopupFormOpen = false;
  }

  initializeForm() {
    this.enquiryform = this.fb.group({
      FullName: ['', Validators.required],
      MobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      Email: ['', Validators.email],
      Company: [''],
      OTP: ['']
    });
  }

  initializeDemoForm() {
    this.demoForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\+?[1-9]\d{1,14}$/)]],
      company: ['']
    });
  }

  get mobileNumber() {
    return this.enquiryform.get('MobileNumber');
  }

  verifyMobileNumber() {
    if(this.mobileNumber?.valid && this.mobileNumber?.value !== '') {
      this.isMobileVerified = true;

      this.sendOTP();
    } else {
      this.isMobileVerified = false;
    }
  }

  sendOTP(){
    let mobileNumber = +this.enquiryform.controls['MobileNumber'].value;
    if (mobileNumber.toString().length == 10) {
      try {
        let data = {
          contact: mobileNumber
        };

        this.enquiryService.SendOTP(data).subscribe(
          (result) => {

            this.isOTPSent = true;
          },
          (err: Error) => {
            console.log(err.message);
            this.isOTPSent = false;
          });
      } catch (ex) {
        console.log(ex);
        this.isOTPSent = false;
      }
    }
  }

  async verifyOTP(): Promise<boolean> {
    let mobileNumber = this.enquiryform.controls['MobileNumber'].value;
    let otp = this.enquiryform.controls['OTP'].value;
  
    if (mobileNumber && otp) {
      try {
        let data = { contact: mobileNumber, otp: otp };
  
        // Convert Observable to Promise and await it
        let result = await firstValueFrom(this.enquiryService.VerifyOTP(data));
  
        return result.message !== 'Invalid OTP'; // Return true if OTP is valid, false otherwise
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  
    return false;
  }

  async save() {
    if (this.enquiryform.valid) {
      this.isOTPValid = await this.verifyOTP();

      if (this.isOTPValid) {
        const enquiryData = {
          full_name: this.enquiryform.value.FullName,
          mobile_number: this.enquiryform.value.MobileNumber,
          enquiry_email: this.enquiryform.value.Email,
          company: this.enquiryform.value.Company
        };
        this.enquiryService.InsertEnquiry(enquiryData).subscribe(
          (enquiryId: number) => {
            if(enquiryId > 0) {
              this.router.navigate(['thank-you']);
            }
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  }

  scrollToForm(): void {
    this.enquiryForm.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  showFeatureDetails(feature: any): void {
    console.log('Show details for', feature.title);
    // Implement logic to show feature details, perhaps in a modal
  }

  toggleFaq(item: any): void {
    item.isOpen = !item.isOpen;
  }

  // Function to pause the marquee
  pauseMarquee() {
    this.isMarqueePaused = true;
  }

  // Function to resume the marquee
  resumeMarquee() {
    this.isMarqueePaused = false;
  }



  // onNextClick(): void {
  //   const sectorsSwiper = document.querySelector('.sectors-slider')?.swiper;
  //   if (sectorsSwiper instanceof Swiper) {
  //     sectorsSwiper.slideNext();
  //   }
  // }

  // onPrevClick(): void {
  //   const sectorsSwiper = document.querySelector('.sectors-slider')?.swiper;
  //   if (sectorsSwiper instanceof Swiper) {
  //     sectorsSwiper.slidePrev();
  //   }
  // }




}
