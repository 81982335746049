import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/guards/auth.guard';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SubscriberLoginComponent } from './components/subscriber-login/subscriber-login.component';
import { SubscriberSignupComponent } from './components/subscriber-signup/subscriber-signup.component';
import { ViewCompanyComponent } from './components/view-company/view-company.component';
import { SubscriberForgotPasswordComponent } from './components/subscriber-forgot-password/subscriber-forgot-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LandingPageComponent } from './components/front-end/web-pages/landing-page/landing-page.component';
import { AboutUsComponent } from './components/front-end/web-pages/about-us/about-us.component';
import { ContactUsComponent } from './components/front-end/web-pages/contact-us/contact-us.component';
import { PricingComponent } from './components/front-end/web-pages/pricing/pricing.component';
import { PaymentComponent } from './components/front-end/web-pages/payment/payment.component';
import { TermsAndConditionsComponent } from './components/front-end/web-pages/termsAndConditions/termsAndConditions.component';
import { PrivacyPolicyComponent } from './components/front-end/web-pages/privacyPolicy/privacyPolicy.component';
import { ShippingAndDeliveryComponent } from './components/front-end/web-pages/shipping-and-delivery/shipping-and-delivery.component';
import { CancellationAndRefundComponent } from './components/front-end/web-pages/cancellation-and-refund/cancellation-and-refund.component';
import { BlogLoginComponent } from './components/blog/blog-login/blog-login.component';
import { AddBlogComponent } from './components/blog/add-blog/add-blog.component';
import { ViewBlogComponent } from './components/blog/view-blog/view-blog.component';
import { EnquiryComponent } from './components/front-end/web-pages/enquiry/enquiry.component';
import { ThankYouComponent } from './components/front-end/web-pages/thank-you/thank-you.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent, canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'subscriber-signup', component: SubscriberSignupComponent},
  { path: 'subscriber-login', component:SubscriberLoginComponent},
  { path: 'subscriber-login/:subscriberID', component:SubscriberLoginComponent},
  { path: 'view-company', component:ViewCompanyComponent, canActivate: [AuthGuard] },
  { path: 'add-company', component: AddCompanyComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'subscriber-forgot-password', component:SubscriberForgotPasswordComponent },
  { path: 'forgot-password', component:ForgotPasswordComponent },
  { path: 'about-us', component:AboutUsComponent},
  { path: 'contact-us', component:ContactUsComponent},
  { path: 'pricing', component:PricingComponent},
  { path: 'payment', component:PaymentComponent},
  { path: 'enquiry', component: EnquiryComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'termsAndConditions', component:TermsAndConditionsComponent},
  { path: 'privacyPolicy', component:PrivacyPolicyComponent},
  { path: 'shipping-and-delivery', component:ShippingAndDeliveryComponent},
  { path: 'cancellation-and-refund', component:CancellationAndRefundComponent},
  { path: 'blog-login', component:BlogLoginComponent },
  { path: 'add-blog', component:AddBlogComponent, canActivate: [AuthGuard] },
  { path: 'view-blog', component:ViewBlogComponent, canActivate: [AuthGuard] },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule)
  }, // lazy loading
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
