import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnquiryService } from 'src/app/services/enquiry.service';
import Swiper, { Navigation } from 'swiper';
import { trigger, state, style, animate, transition } from '@angular/animations';

Swiper.use([Navigation]);

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css'],
  animations: [
    trigger('faqAnimation', [
      state('closed', style({ height: '0px', opacity: 0 })),
      state('open', style({ height: '*', opacity: 1 })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class EnquiryComponent implements OnInit, AfterViewInit {
  @ViewChild('enquiryForm') enquiryForm!: ElementRef;
  // @ViewChild('backgroundCanvas', { static: false }) backgroundCanvas: ElementRef;

  enquiryform!: FormGroup;
  demoForm!: FormGroup;
  isOTPSent: boolean = false;
  isOTPVerified: boolean = false;
  isMobileVerified: boolean = false;
  isMarqueePaused: boolean = false;
  isPopupFormOpen: boolean = false;


  slides = [
    { image: 'https://logoipsum.com/artwork/330' },
    { image: 'path/to/slide2.jpg' },
    { image: 'path/to/slide3.jpg' }
  ];

  features = [
    { icon: 'fas fa-warehouse', title: 'Inventory Management', description: 'Track and manage your stock levels efficiently, with automated inventory tracking and low stock alerts. Manage stock levels, track orders, and optimize inventory  .', cta: 'Discover More', height: '120px' },
    { icon: 'fas fa-cash-register', title: 'Point of Sale', description: 'Streamline your sales process with our intuitive POS system, including inventory management and sales tracking. Manage customer interactions and process payments.', cta: 'Explore POS', height: '120px' },
    { icon: 'fas fa-chart-line', title: 'Advanced Reporting', description: 'Gain valuable insights with comprehensive analytics and reporting tools, including sales reports and customer analytics. Analyze sales trends and track customer behavior.', cta: 'Analyze Data', height: '120px' },
    { icon: 'fas fa-users', title: 'Customer Management', description: 'Manage customer relationships and enhance engagement with our customer management system, including customer profiling and communication tools.', cta: 'Start Managing', height: '120px' },
    { icon: 'fas fa-truck', title: 'Supply Chain Management', description: 'Optimize your supply chain for better efficiency with our supply chain management system, including procurement and inventory management. Manage your supply chain.', cta: 'Supply Chain', height: '120px' },
    { icon: 'fas fa-file-invoice-dollar', title: 'Financial Management', description: 'Comprehensive tools for accounting and budgeting, including invoicing, payment tracking, and expense management. Manage your finances and track expenses.', cta: 'Financial Clarity', height: '120px' },
    { icon: 'fas fa-cogs', title: 'Workflow Automation', description: 'Automate repetitive tasks to save time and reduce errors with our workflow automation system, including automated workflows and task management. Automate tasks.', cta: 'Automate Tasks', height: '120px' },
    { icon: 'fas fa-shield-alt', title: 'Data Security', description: 'Protect your data with advanced security measures, including data encryption, secure logins, and access controls. Keep your data safe and secure.', cta: 'Secure Data', height: '120px' },
    { icon: 'fas fa-mobile-alt', title: 'Mobile Access', description: 'Access your ERP system on the go with mobile compatibility, including mobile apps and responsive design. Access your data on the go.', cta: 'Mobile App', height: '120px' },
    { icon: 'fas fa-chart-pie', title: 'Business Intelligence', description: 'Leverage data analytics for informed decision-making with our business intelligence system, including data visualization and reporting tools.', cta: 'Custom Reports', height: '120px' },
    { icon: 'fas fa-bolt', title: 'Real-Time Analytics', description: 'Get instant insights into your business performance with real-time analytics and reporting tools, including sales tracking and customer analytics. Track your sales.', cta: 'Get Insights', height: '120px' },
    { icon: 'fas fa-sync-alt', title: 'Seamless Integration', description: 'Easily integrate with existing systems and tools, including API integrations and third-party integrations. Integrate your ERP system with other tools.', cta: 'Integrate Now', height: '120px' }
  ];

  clients = [
    { name: 'Balwant Collection', logo: '../../../../../assets/images/enquiry/client-logos/Balwwant-Collection.avif' },
    { name: 'Shree Electronics', logo: '../../../../../assets/images/enquiry/client-logos/Shree-Electronics.avif' },
    { name: 'IDM Industries', logo: '../../../../../assets/images/enquiry/client-logos/IDM-Industries.avif' },
    { name: 'Krishna Textiles', logo: '../../../../../assets/images/enquiry/client-logos/Krishna-Textiles.avif' },
    { name: 'Mauli Chemicals', logo: '../../../../../assets/images/enquiry/client-logos/Mauli-Chemicals.avif' },
    { name: 'Sai Transtech', logo: '../../../../../assets/images/enquiry/client-logos/Sai-Transtech-logo.avif' },
    { name: 'Shehnai', logo: '../../../../../assets/images/enquiry/client-logos/Shehnai.avif' },
    { name: 'VED ENTERPRISES', logo: '../../../../../assets/images/enquiry/client-logos/VED-ENTERPRISES.avif' },
    // Add more clients as needed
  ];

  detailedFeatures = [
    { icon: 'fas fa-file-invoice-dollar', title: 'Financial Management', description: 'Comprehensive tools for accounting, budgeting, and financial reporting', callToAction: 'Learn More' },
    { icon: 'fas fa-user-tie', title: 'HR & Payroll', description: 'Streamline HR processes and manage payroll with ease', callToAction: 'Discover How' },
    { icon: 'fas fa-address-book', title: 'Customer Relationship Management', description: 'Enhance customer interactions and boost sales', callToAction: 'Get Started' },
    { icon: 'fas fa-chart-line', title: 'Sales Analytics', description: 'Analyze sales data to drive business growth', callToAction: 'Sign Up Today' }, // New feature
    { icon: 'fas fa-cogs', title: 'Workflow Automation', description: 'Automate repetitive tasks to improve efficiency', callToAction: 'Try For Free' }, // New feature
    { icon: 'fas fa-shield-alt', title: 'Data Security', description: 'Protect sensitive information with advanced security measures', callToAction: 'Book a Demo' } // New feature
  ];

  testimonials = [
  {
    quote: "A-Square ERP has revolutionized our business operations. The efficiency gains are remarkable!",
    name: "John Doe",
    position: "CEO",
    company: "TechInnovate Inc.",
    avatar: "https://picsum.photos/100/100?random=1"
  },
  {
    quote: "The support team at A-Square is exceptional. They're always there when we need them.",
    name: "Jane Smith",
    position: "Operations Manager",
    company: "Global Solutions Ltd.",
    avatar: "https://picsum.photos/100/100?random=2"
  },
  {
    quote: "Implementing A-Square ERP was a game changer for us. Our productivity has increased significantly!",
    name: "Alice Johnson",
    position: "CTO",
    company: "Innovatech Solutions",
    avatar: "https://picsum.photos/100/100?random=3"
  },
  {
    quote: "The user-friendly interface of A-Square ERP makes it easy for our team to adapt and thrive.",
    name: "Michael Brown",
    position: "Project Manager",
    company: "FutureTech Corp.",
    avatar: "https://picsum.photos/100/100?random=4"
  },
  {
    quote: "A-Square ERP has streamlined our processes and improved our overall efficiency.",
    name: "Emily Davis",
    position: "Operations Director",
    company: "GreenWave Industries",
    avatar: "https://picsum.photos/100/100?random=5"
  },
  {
    quote: "The analytics features in A-Square ERP provide us with valuable insights for decision-making.",
    name: "David Wilson",
    position: "Business Analyst",
    company: "DataDriven Inc.",
    avatar: "https://picsum.photos/100/100?random=6"
  }
  // Add more testimonials as needed
];

  faqItems = [
    { question: 'What industries does A-Square ERP cater to?', answer: 'A-Square ERP is versatile and caters to various industries including manufacturing, retail, healthcare, and more. Our solutions are customizable to meet specific industry needs.', isOpen: false },
    { question: 'How long does implementation typically take?', answer: 'Implementation time varies depending on the complexity of your business and the modules you choose. On average, it takes 2-4 months, but we work closely with you to ensure a smooth and efficient process.', isOpen: false },
    // Add more FAQ items
  ];

sectorsimg = [
  { imgSrc: '../../../../../assets/images/enquiry/sectors/auto-accessories.avif', alt: 'auto-accessories', label: 'Auto Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/auto-parts-store.avif', alt: 'auto-parts-store', label: 'Auto Parts Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/Book-store.avif', alt: 'Book-store', label: 'Book Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/boutiques.avif', alt: 'boutiques', label: 'Boutiques' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/camera-and-accessories-shop.avif', alt: 'camera-and-accessories-shop', label: 'Camera & Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/car-accessories-store.avif', alt: 'car-accessories-store', label: 'Car Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/clothing-store.avif', alt: 'clothing-store', label: 'Clothing Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/coffee-shop.avif', alt: 'coffee-shop', label: 'Coffee Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/computer-hardware-and-accessories-store.avif', alt: 'computer-hardware-and-accessories-store', label: 'Computer Hardware' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/convenience-store.avif', alt: 'convenience-store', label: 'Convenience Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/cosmetics-store.avif', alt: 'cosmetics-store', label: 'Cosmetics Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/departmental-store.avif', alt: 'departmental-store', label: 'Departmental Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/electronics-store.avif', alt: 'electronics-store', label: 'Electronics Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/fashion-accessories.avif', alt: 'fashion-accessories', label: 'Fashion Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/Footwear-Store.avif', alt: 'Footwear-Store', label: 'Footwear Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/furniture-mall.avif', alt: 'furniture-mall', label: 'Furniture Mall' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/gift-shop.avif', alt: 'gift-shop', label: 'Gift Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/grocery-store.avif', alt: 'grocery-store', label: 'Grocery Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/gym-equipments.avif', alt: 'gym-equipments', label: 'Gym Equipments' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/hardware-shop.avif', alt: 'hardware-shop', label: 'Hardware Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/hardware-store.avif', alt: 'hardware-store', label: 'Hardware Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/home-and-kitchen-appliancess.avif', alt: 'home-and-kitchen-appliancess', label: 'Home & Kitchen' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/home-decor.avif', alt: 'home-decor', label: 'Home Decor' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/Household-Products-Store.avif', alt: 'Household-Products-Store', label: 'Household Products' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/imitation-jewellery-shop.avif', alt: 'imitation-jewellery-shop', label: 'Imitation Jewellery' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/Kirana-Shop.avif', alt: 'Kirana-Shop', label: 'Kirana Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/kitchen-utensils.avif', alt: 'kitchen-utensils', label: 'Kitchen Utensils' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/luggage-and-bags.avif', alt: 'luggage-and-bags', label: 'Luggage & Bags' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/mobile-shop.avif', alt: 'mobile-shop', label: 'Mobile Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/mobile-store.avif', alt: 'mobile-store', label: 'Mobile Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/optical-store.avif', alt: 'optical-store', label: 'Optical Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/pet-store.avif', alt: 'pet-store', label: 'Pet Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/plastic-store.avif', alt: 'plastic-store', label: 'Plastic Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/sports-and-gym-equipment-store.avif', alt: 'sports-and-gym-equipment-store', label: 'Sports & Gym' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/stationery-store.avif', alt: 'stationery-store', label: 'Stationery Store' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/sweet-shop.avif', alt: 'sweet-shop', label: 'Sweet Shop' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/watch-and-accesories.avif', alt: 'watch-and-accesories', label: 'Watch & Accessories' },
  { imgSrc: '../../../../../assets/images/enquiry/sectors/GAJANAN.avif', alt: 'Gajanan packers logo', label: 'Gajanan packers' },
  { imgSrc: '../../../../../assets/images/enquiry/SHREE SAI.avif', alt: 'Shree sai packers logo', label: 'Shree Sai Packers' },
  { imgSrc: '../../../../../assets/images/enquiry/SHREE SWAMI.avif', alt: 'Shree sai enterprises logo', label: 'Shree Sai Enterprises Wooden Box' },
  // Add more objects as needed
];

  constructor(private enquiryService: EnquiryService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeDemoForm();
  }

  ngAfterViewInit(): void {
    const enquirySwiper = new Swiper('.mySwiper', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      rewind: true,
      loop: true,
      autoplay: {
        delay: 800,
      },
    });

    const infiniteSwiper = new Swiper('#infiniteSwiper', {
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      rewind: true,
      loop: true,
      autoplay: {
        delay: 800,
      },
    });
  
    const infiniteSwiper2 = new Swiper('#infiniteSwiper2', {
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      rewind: true,
      loop: true,
      autoplay: {
        delay: 800,
      },
    });

    const testimonialSwiper = new Swiper('.testimonial-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      loop: true,
      autoplay: {
        delay: 800,
      },
      rewind: true,
    });

    const verticalSwiper = new Swiper('.vertical-swiper', {
      direction: 'vertical',
      loop: true,
      autoplay: {
        delay: 800,
      },
    });

    let sectorsSwiper = new Swiper('.sectors-slider', {
      slidesPerView: 1,
      spaceBetween: 64,
      loop: true,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    Swiper.use([Navigation]);

    setTimeout(() => {
      if(!this.isPopupFormOpen) {
        this.isPopupFormOpen = true;
      }
    }, 247500);

  }

  @ViewChild('mainDiv') mainDiv: ElementRef;

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  //   const windowHeight = window.innerHeight;
  //   const documentHeight = document.documentElement.scrollHeight;

  //   // Calculate the scroll position as a percentage
  //   const scrollPercentage = (scrollTop + windowHeight) / documentHeight;

  //   // Trigger modal when the scroll position reaches or exceeds 50%
  //   if (scrollPercentage >= 0.5 && !this.isPopupFormOpen) {
  //     this.isPopupFormOpen = true;
  //   }
  // }

  
  closeModal() {
    this.isPopupFormOpen = false;
  }

  initializeForm() {
    this.enquiryform = this.fb.group({
      FullName: ['', Validators.required],
      MobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      Email: ['', [Validators.required, Validators.email]],
      Requirements: ['', Validators.required],
      OTP: ['']
    });
  }

  initializeDemoForm() {
    this.demoForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\+?[1-9]\d{1,14}$/)]],
      company: ['']
    });
  }

  get mobileNumber() {
    return this.enquiryform.get('MobileNumber');
  }

  verifyMobileNumber() {
    if(this.mobileNumber?.valid && this.mobileNumber?.value !== '') {
      this.isMobileVerified = true;
    } else {
      this.isMobileVerified = false;
    }
  }

  sendOTP(){
    let mobileNumber = +this.enquiryform.controls['MobileNumber'].value;
    if (mobileNumber.toString().length == 10) {
      try {
        let data = {
          contact: mobileNumber
        };

        this.enquiryService.SendOTP(data).subscribe(
          (result) => {

            this.isOTPSent = true;
          },
          (err: Error) => {
            console.log(err.message);
            this.isOTPSent = false;
          });
      } catch (ex) {
        console.log(ex);
        this.isOTPSent = false;
      }
    }
  }

  verifyOTP(){
    let mobileNumber = this.enquiryform.controls['MobileNumber'].value;
    let otp = this.enquiryform.controls['OTP'].value;
    if (mobileNumber && otp) {
      try {
        let data = {
          contact: mobileNumber,
          otp: otp
        };

        this.enquiryService.VerifyOTP(data).subscribe(
          (result) => {

            this.isOTPVerified = true;
          },
          (err: Error) => {
            console.log(err.message);
            this.isOTPVerified = false;
          });
      } catch (ex) {
        console.log(ex);
        this.isOTPVerified = false;
      }
    }
  }

  save() {
    if (this.enquiryform.valid) {
      const enquiryData = {
        full_name: this.enquiryform.value.FullName,
        mobile_number: this.enquiryform.value.MobileNumber,
        email: this.enquiryform.value.Email,
        requirements: this.enquiryform.value.Requirements
      };
      this.enquiryService.InsertEnquiry(enquiryData).subscribe(
        () => {
          // Handle successful submission
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  scrollToForm(): void {
    this.enquiryForm.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onSubmit(): void {
    if (this.demoForm.valid) {
      console.log('Form submitted', this.demoForm.value);
      // Implement form submission logic here
    }
  }

  showFeatureDetails(feature: any): void {
    console.log('Show details for', feature.title);
    // Implement logic to show feature details, perhaps in a modal
  }

  toggleFaq(item: any): void {
    item.isOpen = !item.isOpen;
  }

  // Function to pause the marquee
  pauseMarquee() {
    this.isMarqueePaused = true;
  }

  // Function to resume the marquee
  resumeMarquee() {
    this.isMarqueePaused = false;
  }



  // onNextClick(): void {
  //   const sectorsSwiper = document.querySelector('.sectors-slider')?.swiper;
  //   if (sectorsSwiper instanceof Swiper) {
  //     sectorsSwiper.slideNext();
  //   }
  // }

  // onPrevClick(): void {
  //   const sectorsSwiper = document.querySelector('.sectors-slider')?.swiper;
  //   if (sectorsSwiper instanceof Swiper) {
  //     sectorsSwiper.slidePrev();
  //   }
  // }

 
}
