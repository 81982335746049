// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    // production: false,
    // ASquareAPIURL: 'https://a-square-erp-service.azurewebsites.net/',
    // SiteKey : '6LfVT9AkAAAAAOACH0mKKMpWBhKBod_DrnOgnZt0'

    ASquareAPIURL: 'https://a-square-erp-service-a-square-erp-service-prod.azurewebsites.net/',
    // ASquareAPIURL: 'https://localhost:7233/',
    // ASquareAPIURL: 'http://localhost:8001/',
    recaptcha: {
      siteKey: '6LfVT9AkAAAAAOACH0mKKMpWBhKBod_DrnOgnZt0',
    },
    CookieEncryptionKey : 'USYdjkcEh6adagRsdPl',
    razorKey : "rzp_live_qq7ep2BPxJGIhL"
};

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

