<div class="footer-div row col-12 mt-5 ms-0 me-0">
    <div class="about-company row col-12 col-sm-10 ms-3 mb-4 ms-sm-auto me-sm-auto mt-5 ps-0 pe-0">
        <div class="logo row col-6 col-sm-4 col-md-3 col-lg-2 ms-0 ps-0  me-auto mb-2">
            <img (click)="redirectTo('')" src="../../../../../assets/images/logo.png" alt="" class="col-12 ps-0">
        </div>
        <h3 class="fw-bolder ps-1">About</h3>
        <p class="w-100 ps-1">"Accurate reporting of stock and profit with advance features like sorting and grouping, helped
            us make difficult task easy. The system is very user friendly and intuitive."</p>
    </div>

    <div class="row col-12 ms-0 ms-sm-auto me-sm-auto ps-0 pe-0 justify-content-center align-content-md-center">
        <div class="sign-up row col-12 col-md-4 mb-4 mb-md-0 text-sm-center">
            <h4>Ready to get started?</h4>
            <div class="buttons row col-12 gap-2  ps-sm-4">
                <a (click)="redirectTo('subscriber-login')" class="col-5 col-sm-4 me-sm-3 me-lg-1 text-center pt-1 pb-1 mb-3 fw-bold">Login</a>
                <a (click)="redirectTo('subscriber-signup')" class="col-5 col-sm-4 text-center pt-1 pb-1 fw-bold">Signup</a>
            </div>
        </div>

        <div class="site-map  row col-12 col-md-8 col-lg-6 me-2 justify-content-sm-center align-content-md-center">
            <div class="site-map-card col-6 col-sm-4 col-md-3 me-md-0 me-sm-2 text-start text-sm-center">
                <i class="fas fa-home col-12"></i>
                <a (click)="redirectTo('')" class="col-12 fw-bold">Home</a>
            </div>

            <div class="site-map-card col-6 col-sm-4 col-md-3 me-md-0 me-sm-2 text-start text-sm-center">
                <i class="fas fa-building col-12"></i>
                <a (click)="redirectTo('about-us')" class="col-12 fw-bold">About Us</a>
            </div>

            <div class="site-map-card col-6 col-sm-4 col-md-3 me-md-0 me-sm-2 text-start text-sm-center">
                <i class="fas fa-rupee-sign col-12 mt-3 mt-md-0"></i>
                <a (click)="redirectTo('pricing')" class="col-12 fw-bold">Pricing</a>
            </div>

            <div class="site-map-card col-6 col-sm-4 col-md-3 me-md-0 me-sm-2 text-start text-sm-center">
                <i class="fas fa-phone col-12 mt-3 mt-md-0"></i>
                <a (click)="redirectTo('contact-us')" class="col-12 fw-bold">Contact Us</a>
            </div>
        </div>

        <div class="terms row col-12 text-center mt-4 ps-0 pe-0 pt-3 pb-1 justify-content-sm-center justify-content-md-center">
            <a (click)="redirectTo('termsAndConditions')" class="col-5 col-md-3 col-lg-2 mt-md-2">Terms & conditions</a>
            <a (click)="redirectTo('privacyPolicy')" class="col-5 col-md-3 col-lg-2 mt-md-2">Privacy Policy</a>
            <a (click)="redirectTo('shipping-and-delivery')" class="col-5 col-md-3 col-lg-2 mt-md-2">Shipping and Delivery</a>
            <a (click)="redirectTo('cancellation-and-refund')" class="col-5 col-md-3 mt-md-2">Cancellation and Refund</a>
            <p class="col-10 col-md-8 mt-3">&copy;2023 A-Square ERP Solutions Private Limited. All rights Reserved</p>
        </div>
    </div>
</div>
