import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from 'src/app/services/common/encryption.service';
import { LogService } from 'src/app/services/common/log.service';
import Swal from "sweetalert2";

import { isPlatform, Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { Plugins } from '@capacitor/core';
// const { GoogleAuth } = Plugins;
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-subscriber-login',
  templateUrl: './subscriber-login.component.html',
  styleUrls: ['./subscriber-login.component.css']
})
export class SubscriberLoginComponent {
  ErrorMessage: string;
  subscriberLoginGroup: FormGroup;
  googleGroup: FormGroup;
  hide = true;
  Username = new FormControl('', [Validators.required, Validators.email]);
  selectedValue: string;
  signUpSubscriberID = "";
  loginSubscriberID = "";

  // IsNewSubscription = 0;
  InvalidLogin = 0;
  IsPaymentCompleted = 0;

  reCaptchaVerfication = null;

  CaptchaSiteKey = environment.recaptcha.siteKey;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';

  constructor(private subscriberService: SubscriberService,
     private router: Router, private logService: LogService, public platform: Platform,
    private authService: AuthService) {}

    async ngOnInit() {
      try {
        this.initForm();
      } catch (ex) {
        this.logService.InsertExceptionLog(ex, "Subscriber Login", "ngOnInit");
        console.log(ex);
      }
    }

  ngAfterViewInit(): void {
    if (!this.platform.is('capacitor')) {
      this.initGoogleSignInWeb();
    }
  }

  initGoogleSignInWeb(){
     // @ts-ignore
     google.accounts.id.initialize({
      client_id: "219534928011-mg5qkftpsbm6lf1jfk2dp158ciu6eq4c.apps.googleusercontent.com",
      callback: this.handleCredentialResponse.bind(this),
      use_fedcm_for_prompt: true,
      auto_select: false,
      cancel_on_tap_outside: true,
    });

    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById("google-button"),
      {
        theme: "outline",
        size: "medium",
        width: "150px",
        text: "Google", // Custom text on the button
        shape: "pill", // Button shape: 'rectangular' or 'pill'
        isSignedIn: false,    // Display the button only if the user is not signed in
        prompt: "select_account", // Prompt user to select an account
      }
    );

    // @ts-ignore
    google.accounts.id.prompt((notification: PromptMomentNotification) => {});
  }

  // Google Sign in
  async handleCredentialResponse(response: any) {
    try{
      if (response.credential) {
        const idToken = response.credential;
        const decodedToken = this.decodeIdToken(idToken);

        if (decodedToken) {
          const userEmail = decodedToken.email;

          this.googleGroup = new FormGroup({
            Email : new FormControl(userEmail)
          });

          this.subscriberService.GetPasswordFromInstituteEmail(this.googleGroup.value)
          .subscribe
          ((result) => {
            if(result != null){
              this.subscriberLoginGroup = new FormGroup({
                Username: new FormControl(userEmail),
                Password: new FormControl(result),
                rememberMe : new FormControl()
              });
              this.Userlogin(1);
            }
          },
          (err: Error) =>{
            console.log("Error in GetPasswordFromInstituteEmail API : " + err);
          });
        }
      }
    }catch(ex){
      console.log("Error in handleCredentialResponse : " + ex);
    }
  }

  decodeIdToken(idToken: string) {
    try {
      const payload = JSON.parse(atob(idToken.split('.')[1]));
      return payload;
    } catch (error) {
      console.error("Error in decodeIdToken : ", error);
      return null;
    }
  }

  initForm() {
    try{
      this.subscriberLoginGroup = new FormGroup({
        Username: new FormControl("", [Validators.required]),
        Password: new FormControl("", [Validators.required]),
        rememberMe : new FormControl()
      });
      this.reCaptchaVerfication = null;
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber Login","initForm");
      console.log(ex);
    }
  }

  async signInWithGoogle() {
    if (this.platform.is('capacitor')) {
      // Use Capacitor Google Auth for mobile
      try {
        await GoogleAuth.initialize({
          clientId: '219534928011-mg5qkftpsbm6lf1jfk2dp158ciu6eq4c.apps.googleusercontent.com',
          scopes: ['profile', 'email'],
          grantOfflineAccess: true
        });
        const googleUser = await GoogleAuth.signIn();
        console.log('Google User:', googleUser);
        if(googleUser) {
          let userEmail = googleUser.email;
          this.handleAndroidResponse(userEmail);
        }
      } catch (err) {
        this.logService.InsertExceptionLog(err,"Subscriber Login","signInWithGoogle");
        console.error('Error:', err);
        return null;
      }
    }
  }

  async handleAndroidResponse(response: any) {
    try{
      if (response) {
        const userEmail = response;

        this.googleGroup = new FormGroup({
          Email : new FormControl(userEmail)
        });

        this.subscriberService.GetPasswordFromInstituteEmail(this.googleGroup.value)
        .subscribe
        ((result) => {
          if(result != null){
            this.subscriberLoginGroup = new FormGroup({
              Username: new FormControl(userEmail),
              Password: new FormControl(result),
              rememberMe : new FormControl()
            });
            this.Userlogin(1);
          }
        },
        (err: Error) =>{
          console.log("Error in GetPasswordFromInstituteEmail API : " + err);
        });
      }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber Login","signInAndroid");
      console.log("Error in handleCredentialResponse : " + ex);
    }
  }

  Userlogin(IsCookieLogin?) {
    try {
      this.InvalidLogin = 0;

      if(this.reCaptchaVerfication == null && IsCookieLogin == undefined) {
        alert("Please tick I am not a robot!");
      }
      else {
        if (this.subscriberLoginGroup.valid) {
          let login = {
            Username: this.subscriberLoginGroup.value.Username,
            Password: this.subscriberLoginGroup.value.Password,
            RememberMe: this.subscriberLoginGroup.value.rememberMe
          };

          this.authService.subscriberLogin(login).subscribe(
            (result) => {
              if(result.success != 0 && result.success == 1){
                this.initForm();

                if(result.subscriptionLeft <= 10){
                  if(result.subscriptionLeft <= 0){
                    Swal.fire({
                      text: "Your subscription has expired. Please renew your package.",
                      icon: "warning"
                    });
                  }
                  else{
                    Swal.fire({
                      text: "Your subscription has elasping in " + result.subscriptionLeft + " days. Please renew your package.",
                      icon: "warning"
                    });
                  }
                }

                this.router.navigate(['view-company'], { queryParams: { subscriberID: result.subscriberID } });
              }
              else{
                this.InvalidLogin = 1;
              }
            }, (err: Error) => {
              this.InvalidLogin = 1;
              console.log(err.message);
          });
        }
      }
    } catch (ex) {
      this.logService.InsertExceptionLog(ex,"Subscriber Login","Userlogin");
      console.log(ex);
    }
  }

  handleSuccess(data) {
    this.reCaptchaVerfication = data;
  }

  getErrorMessage() {
    if (this.Username.hasError('required')) {
      return 'You must enter a value';
    }

    return this.Username.hasError('Username') ? 'Not a valid Username' : '';
  }

  redirectToSignup(){
    this.router.navigate(
      ['subscriber-signup']
    );
  }

  redirectToForgotPassword(){
    this.router.navigate(
      ['subscriber-forgot-password']
    );
  }

  // ResetNewSubscription(){
  //   try{
  //     this.IsNewSubscription = 0;
  //   }catch(ex){
  //     this.logService.InsertExceptionLog(ex,"Subscriber Login","ResetNewSubscription");
  //     console.log(ex);
  //   }
  // }
}
