import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ASquare ERP';

  constructor(private router: Router) {}

  ngOnInit(): void {
    
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const shortcutMap: { [key: string]: { path: string, queryParams?: { [key: string]: any } } } = {
      'alt+d': { path: 'admin/home' },
      'alt+i': { path: 'admin/master/item/add' },
      'alt+shift+i': { path: 'admin/master/item/view' },
      'alt+l': { path: 'admin/master/ledger/add' },
      'alt+shift+l': { path: 'admin/master/ledger/view' },
      'alt+u': { path: 'admin/master/unit/add' },
      'alt+shift+u': { path: 'admin/master/unit/view' },
      'alt+g': { path: 'admin/master/item-group/add' },
      'alt+shift+g': { path: 'admin/master/item-group/view' },
      'alt+p': { path: 'admin/master/ledger-group/add' },
      'alt+shift+p': { path: 'admin/master/ledger-group/view' },
      'alt+t': { path: 'admin/master/tax-category/add' },
      'alt+shift+t': { path: 'admin/master/tax-category/view' },
      'alt+s': { path: 'admin/master/state/add' },
      'alt+shift+s': { path: 'admin/master/state/view' },
      'alt+c': { path: 'admin/master/country/add' },
      'alt+shift+c': { path: 'admin/master/country/view' },
      'alt+m': { path: 'admin/master/salesman/add' },
      'alt+shift+m': { path: 'admin/master/salesman/view' },
      'alt+b': { path: 'admin/master/billOfMaterial/add' },
      'alt+shift+b': { path: 'admin/master/billOfMaterial/view' },
      'alt+o': { path: 'admin/master/location/add' },
      'alt+shift+o': { path: 'admin/master/location/view' },
      'alt+r': { path: 'admin/master/location-group/add' },
      'alt+shift+r': { path: 'admin/master/location-group/view' },
      'alt+e': { path: 'admin/master/transporter/add' },
      'alt+shift+e': { path: 'admin/master/transporter/view' },
      'alt+x': { path: 'admin/master/import' },
      'alt+y': { path: 'admin/master/opening/select' },
      'alt+q': { path: 'admin/sales/order/add' },
      'alt+shift+q': { path: 'admin/sales/order/view' },
      'alt+w': { path: 'admin/sales/issued/add' },
      'alt+shift+w': { path: 'admin/sales/issued/view' },
      'alt+z': { path: 'admin/sales/invoice/add' },
      'alt+shift+z': { path: 'admin/sales/invoice/view' },
      'alt+v': { path: 'admin/sales/return/add' },
      'alt+shift+v': { path: 'admin/sales/return/view' },
      'alt+j': { path: 'admin/sales/quotation/add' },
      'alt+shift+j': { path: 'admin/sales/quotation/view' },
      'alt+k': { path: 'admin/purchase/order/add' },
      'alt+shift+k': { path: 'admin/purchase/order/view' },
      'alt+f': { path: 'admin/purchase/received/add' },
      'alt+shift+f': { path: 'admin/purchase/received/view' },
      'alt+h': { path: 'admin/purchase/invoice/add' },
      'alt+shift+h': { path: 'admin/purchase/invoice/view' },
      'alt+F1': { path: 'admin/purchase/return/add' },
      'alt+shift+F1': { path: 'admin/purchase/return/view' },
      'alt+F2': { path: 'admin/voucher/payment/add' },
      'alt+shift+F2': { path: 'admin/voucher/payment/view' },
      'alt+F3': { path: 'admin/voucher/receipt/add' },
      'alt+shift+F3': { path: 'admin/voucher/receipt/view' },
      'ctrl+alt+,': { path: 'admin/voucher/journal/add' },
      'alt+shift+F4': { path: 'admin/voucher/journal/view' },
      'alt+F5': { path: 'admin/voucher/contra/add' },
      'alt+shift+F5': { path: 'admin/voucher/contra/view' },
      'alt+F6': { path: 'admin/voucher/debitnote/add' },
      'alt+shift+F6': { path: 'admin/voucher/debitnote/view' },
      'alt+F7': { path: 'admin/voucher/creditnote/add' },
      'alt+shift+F7': { path: 'admin/voucher/creditnote/view' },
      'alt+F8': { path: 'admin/voucher/production/add' },
      'alt+shift+F8': { path: 'admin/voucher/production/view' },
      'alt+F9': { path: 'admin/voucher/unassemble/add' },
      'alt+shift+F9': { path: 'admin/voucher/unassemble/view' },
      'alt+F10': { path: 'admin/reports/ledger-statement/filter' },
      'alt+F11': { path: 'admin/reports/ledgergroup-summary/filter' },
      'ctrl+alt+b': { path: 'admin/reports/balance-sheet/filter' },
      'ctrl+alt+p': { path: 'admin/reports/profit-loss/filter' },
      'alt+,': { path: 'admin/reports/trial-balance/filter' },
      'ctrl+shift+r': { path: 'admin/reports/bills-receivable/filter' },
      'ctrl+shift+o': { path: 'admin/reports/bills-payable/filter' },
      'alt+F12': { path: 'admin/reports/stock-status/filter' },
      'ctrl+shift+i': { path: 'admin/reports/gstr-1/filter' },
      'ctrl+shift+s': { path: 'admin/reports/gstr-2/filter' },
      'ctrl+shift+e': { path: 'admin/reports/gstr-3b/filter' },
      'ctrl+alt+c': { path: 'admin/reports/cancel-invoices/filter' },
      'ctrl+alt+v': { path: 'admin/settings/features/inventory' },
      'ctrl+shift+l': { path: 'admin/settings/features/ledger' },
      'ctrl+shift+g': { path: 'admin/settings/features/configuregst' },
      'ctrl+alt+z': { path: 'admin/settings/features/dateformat' },
      'ctrl+shift+a': { path: 'admin/settings/features/autoalias' },
      'ctrl+alt+f': { path: 'admin/settings/configuration/entry', queryParams: { vchtype: 'Sales Order' } },
      'alt+[': { path: 'admin/settings/numbering/entry', queryParams: { vchtype: 'Sales Order' } },
      'alt+]': { path: 'admin/settings/printer-template/invoice/add-invoice-printertemplate' },
      'ctrl+shift+d': { path: 'admin/settings/printer-template/invoice/view-invoice-printertemplate' },
      'alt+;': { path: 'admin/settings/printer-template/voucher/add-voucher-printertemplate' },
      'ctrl+shift+h': { path: 'admin/settings/printer-template/voucher/view-voucher-printertemplate' },
      'ctrl+alt+j': { path: 'admin/settings/email-preferences/add-sender' },
      'ctrl+shift+j': { path: 'admin/settings/email-preferences/view-sender' },
      'alt+.': { path: 'admin/settings/configure-barcode/add-barcode' },
      'ctrl+shift+x': { path: 'admin/settings/configure-barcode/view-barcode' },
      'alt+/': { path: 'admin/settings/configSMS/add-configSMS' },
      'ctrl+shift+m': { path: 'admin/settings/configSMS/view-configSMS' },
      'ctrl+alt+k': { path: 'admin/settings/smstemplate/add-smstemplate' },
      'ctrl+shift+k': { path: 'admin/settings/smstemplate/view-smstemplate' },
      'ctrl+alt+;': { path: 'admin/settings/edit-company' },
      'ctrl+Shift+u': { path: 'admin/settings/user/add-user' },
      'ctrl+shift+u': { path: 'admin/settings/user/view-user' },
      'ctrl+alt+w': { path: 'admin/settings/role/add-role' },
      'ctrl+shift+w': { path: 'admin/settings/role/view-role' }
    };
  
    // Convert alphabetic keys to lowercase, but leave function keys as they are
    const key = /^[a-zA-Z]$/.test(event.key) ? event.key.toLowerCase() : event.key;
  
    const keyCombination = [
      event.ctrlKey ? 'ctrl' : '',
      event.altKey ? 'alt' : '',
      event.shiftKey ? 'shift' : '',
      key,
    ]
      .filter(Boolean)
      .join('+');
  
      const routeConfig = shortcutMap[keyCombination];

      if (routeConfig) {
        event.preventDefault();
        // Navigate with or without query parameters
        if (routeConfig.queryParams) {
          this.router.navigate([routeConfig.path], { queryParams: routeConfig.queryParams });
        } else {
          this.router.navigate([routeConfig.path]);
        }
      }
  }
}
