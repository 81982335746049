import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { Platform } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { LogService } from 'src/app/services/common/log.service';
import { SubscriberService } from 'src/app/services/subscriber.service';

@Component({
  selector: 'app-subscriber-signup',
  templateUrl: './subscriber-signup.component.html',
  styleUrls: ['./subscriber-signup.component.css']
})
export class SubscriberSignupComponent implements OnInit, AfterViewInit {
  ErrorMessage: string;
  subscriberformGroup: FormGroup;
  hide = true;
  isShowErrorMessage = 0;
  isEmailIDExist = 0;

  isOTPSent: boolean = false;
  isOTPValid: boolean | null = null;

  constructor(private subscriberService: SubscriberService,
     private router: Router,
     private logService:LogService,
     public platform: Platform) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    if (!this.platform.is('capacitor')) {
      this.initGoogleSignUpWeb();
    }
  }

  initGoogleSignUpWeb() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: "219534928011-mg5qkftpsbm6lf1jfk2dp158ciu6eq4c.apps.googleusercontent.com",
      callback: this.handleCredentialResponse.bind(this),
      use_fedcm_for_prompt: true,
      auto_select: false,
      cancel_on_tap_outside: true,
    });

    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById("google-signup"),
      {
        theme: "outline",
        size: "medium",
        // innerHeight: "100px",
        // innerWidth:"250px",
        width:"150px",
        text: "Google", // Custom text on the button
        shape: "pill", // Button shape: 'rectangular' or 'pill'
        isSignedIn: false,    // Display the button only if the user is not signed in
        prompt: "select_account", // Prompt user to select an account
      }
    );

    // @ts-ignore
    google.accounts.id.prompt((notification: PromptMomentNotification) => { });
  }

  async handleCredentialResponse(response: any) {
    try {
      if (response.credential) {
        const idToken = response.credential;
        const decodedToken = this.decodeIdToken(idToken);

        if (decodedToken) {
          const userId = decodedToken.sub;
          const userName = decodedToken.name;
          const userEmail = decodedToken.email;
          const password = this.generateRandomPassword()

          this.subscriberformGroup = new FormGroup({
            Name: new FormControl(userName, [Validators.required]),
            EmailID:new FormControl(userEmail,[Validators.required, Validators.email]),
            MobileNumber : new FormControl(""),
            Address: new FormControl(""),
            State: new FormControl(""),
            City:new FormControl(""),
            PinCode:new FormControl(""),
            GstNo:new FormControl(""),
            BusinessType: new FormControl('General'),
            Password: new FormControl(password, [Validators.required])
          });

          this.SubscriberSignUp()

          //this.createNewInstituteRecord(true, userName, password, userEmail);
        }
      }
    } catch (ex) {
      console.log("Error in handleCredentialResponse : " + ex);
    }
  }

  generateRandomPassword(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  }

  decodeIdToken(idToken: string) {
    try {
      const payload = JSON.parse(atob(idToken.split('.')[1]));
      return payload;
    } catch (error) {
      console.error("Error in decodeIdToken : ", error);
      return null;
    }
  }

  async signUpWithGoogleAndroid() {
    if (this.platform.is('capacitor')) {
      // Use Capacitor Google Auth for mobile
      try {
        GoogleAuth.initialize({
          clientId: '219534928011-mg5qkftpsbm6lf1jfk2dp158ciu6eq4c.apps.googleusercontent.com',
          scopes: ['profile', 'email'],
          grantOfflineAccess: true
        });
        const googleUser = await GoogleAuth.signIn();
        console.log('Google User:', googleUser);
        if(googleUser) {
          this.handleAndroidResponse(googleUser);
        }
      } catch (err) {
        this.logService.InsertExceptionLog(err,"Subscriber SignUp","signUpWithGoogleAndroid");
        console.error('Error:', err);
        return null;
      }
    }
  }

  async handleAndroidResponse(response: User) {
    try{
      if (response) {
        const userId = response.id;
        const userName = response.name;
        const userEmail = response.email;
        const password = this.generateRandomPassword()

        this.subscriberformGroup = new FormGroup({
          Name: new FormControl(userName, [Validators.required]),
          EmailID: new FormControl(userEmail, [Validators.required, Validators.email]),
          MobileNumber: new FormControl(""),
          Address: new FormControl(""),
          State: new FormControl(""),
          City: new FormControl(""),
          PinCode: new FormControl(""),
          GstNo: new FormControl(""),
          Password: new FormControl(password, [Validators.required])
        });

        this.SubscriberSignUp()
      }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber SignUp","signInAndroid");
      console.log("Error in handleCredentialResponse : " + ex);
    }
  }

  initForm() {
    try{
      this.resetEmailIDExist();
      this.subscriberformGroup = new FormGroup({
        Name: new FormControl("", [Validators.required]),
        EmailID:new FormControl("",[Validators.required, Validators.email]),
        MobileNumber : new FormControl("",[Validators.required]),
        Address: new FormControl("",[Validators.required]),
        State: new FormControl("",[Validators.required]),
        City:new FormControl("",[Validators.required]),
        PinCode:new FormControl("",[Validators.required]),
        GstNo:new FormControl("",[Validators.required]),
        BusinessType: new FormControl('General', [Validators.required]),
        Password: new FormControl("", [Validators.required]),
        OTP: new FormControl('', [Validators.required])
      });
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber Signup","initForm");
      console.log(ex);
    }
  }

  sendOTP() {
    let mobileNumber = +this.subscriberformGroup.controls['MobileNumber'].value;
    if (mobileNumber.toString().length == 10) {
      try {
        let data = {
          contact: mobileNumber
        };

        this.subscriberService.SendOTP(data).subscribe(
          (result) => {

            this.isOTPSent = true;
          },
          (err: Error) => {
            console.log(err.message);
            this.isOTPSent = false;
          });
      } catch (ex) {
        console.log(ex);
        this.isOTPSent = false;
      }
    }
  }

  async verifyOTP(): Promise<boolean> {
    let mobileNumber = this.subscriberformGroup.controls['MobileNumber'].value;
    let otp = this.subscriberformGroup.controls['OTP'].value;

    if (mobileNumber && otp) {
      try {
        let data = { contact: mobileNumber, otp: otp };

        // Convert Observable to Promise and await it
        let result = await firstValueFrom(this.subscriberService.VerifyOTP(data));

        return result.message !== 'Invalid OTP'; // Return true if OTP is valid, false otherwise
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    return false;
  }

  async SubscriberSignUp() {
    try {
      this.resetEmailIDExist();
      if(this.subscriberformGroup.valid){
        this.isOTPValid = await this.verifyOTP();
        if (this.isOTPValid) {
          this.subscriberService.InsertSubscriber(this.subscriberformGroup.value).subscribe(
            (result) => {
              if (result.isSuccess == 1 && result.subscriberID > 0) {
                this.initForm();
                this.router.navigate(
                  ['subscriber-login'],
                  { queryParams: { subscriberID: result.subscriberID } }
                )
              }
              else if (result.isSuccess == 0 && result.subscriberID == -1) {
                this.isEmailIDExist = 1;
              }
              else {
                console.log(result);
              }
            },
            (err: Error) => {
              console.log(err.message);
            });
        }
      }
      else{
        this.isShowErrorMessage = 1
      }
    } catch (ex) {
      this.logService.InsertExceptionLog(ex,"Subscriber Signup","SubscriberSignUp");
      console.log(ex);
    }
  }

  resetEmailIDExist(){
    try{
      this.isEmailIDExist = 0;
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber Signup","resetEmailIDExist");
      console.log(ex);
    }
  }

  redirectTo(page){
    this.router.navigate([page]);
  }
}
