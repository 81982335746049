import { createReducer, on } from "@ngrx/store";
import { loadItemsSuccess, loadItemsFailure, addItem, updateItem, loadCompanyLedgersSuccess, loadCompanyLedgersFailure } from "./item.actions";

export interface ItemState {
    items: any[];
    itemLedgers: any[];
    error: string | null;
  }
  
  const initialState: ItemState = {
    items: [],
    itemLedgers: [],
    error: null,
  };

  export const itemReducer = createReducer(
    initialState,
    on(loadItemsSuccess, (state, { items }) => ({
      ...state,
      items,
      error: null,
    })),
    on(loadItemsFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(addItem, (state, { item }) => ({
      ...state,
      items: [...state.items, item],
      error: null
    })),
    on(updateItem, (state, { item }) => ({
      ...state,
      items: state.items.map(i =>
        i.itemID === item.itemID ? { ...i, ...item } : i
      ),
      error: null
    })),
    on(loadCompanyLedgersSuccess, (state, { itemLedgers }) => ({
      ...state,
      itemLedgers,
      error: null,
    })),
    on(loadCompanyLedgersFailure, (state, { error }) => ({
      ...state,
      error,
    }))
  );