<app-web-navbar></app-web-navbar>
<div class="main-div">
    <div class="hero align-items-md-center ms-md-auto me-md-auto ms-xl-0 me-xl-0 mt-md-0 pt-md-5 pb-md-5 ">
        <div class="hero-content ms-3  ms-lg-5">
            <h1>Welcome to A-Square ERP Solutions</h1>
            <p>We provide solutions for <span class="hero-span-element"></span>. Accurately report stock and profit with
                our advanced features, including sorting and grouping, to effortlessly tackle complex tasks. Our
                user-friendly and intuitive system simplifies your experience.</p>
            <a (click)="redirectTo('enquiry')" class="hero-button">Enquire Now</a>
        </div>
        <div class="hero-image ms-3">
            <img src="../../../../../assets/images/home/DeviceMockups.png" alt="">
        </div>
        <div class="hero-background">
        </div>
    </div>

    <div class="testimonials row col-12 col-md-10 justify-content-md-center ms-3 ms-md-auto me-md-auto mb-5 justify-content-md-center">
        <div class="testimonials-header row col-12 mb-3 text-md-center">
            <h2 class="text-center">Hear from our clients</h2>
        </div>
        <div class="swiper-container testimonial-cards col-10 col-sm-8 col-md-12 overflow-hidden pt-4 pb-4 ms-auto me-auto">
            <div class="swiper-wrapper col-12">
                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 col-md-6 col-lg-4 col-xl-3 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Shreeji Electronics</h5>
                            <hr>
                            <p>Sinnar</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>"The software is very reasonable with all the necessary features in it. Service is very prompt. Till date they have provided me with all the required customisations unlike other companies."
                        </p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>

                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 col-md-6 col-lg-4 col-xl-3 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5> Kavya Collection</h5>
                            <hr>
                            <p>Nasik</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>
                            "Whenever we call the company for any support we have been fully satisfied. Staff is very soft spoken. One of the features that I like about this software is its multiple billing window."
                            </p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>

                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 col-md-6 col-lg-4 col-xl-3 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Rane Dairy</h5>
                            <hr>
                            <p>Nasik</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>"I was finding software which will suit the seeds of my dairy shop. Finally I came across A-Square software. They provided me the software will all the necessary customisations which are required in dairy business which are hard to find in other software."</p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>

                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 col-md-6 col-lg-4 col-xl-3 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Chandwadkar Garments</h5>
                            <hr>
                            <p>Nasik</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>"I had used many billing Softwares for my retail shop but my father was not happy. So I decided to buy a new one which will solve my business needs. This is when I came to know about A-Square ERP. Very detailed software at a very cheap cost"</p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>

                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 col-md-6 col-lg-4 col-xl-3 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Tara Telecom</h5>
                            <hr>
                            <p>Ozar</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>"This is a very easy software to use will all the features. I always recommend my friends to use this software whenever they ask me for suggestion."</p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>
                <!-- Add more testimonial-card slides here if needed -->
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>

    <div class="horizontal-cards row col-12 ms-3 ms-md-auto me-md-auto">
        <div class="row horizontal-card align-items-center">
            <div class="col-12 col-md-6 order-md-1">
                <h3 class="col-12">Statistics</h3>
                <hr class="horizontal-card-hr">
                <p class="fw-bolder">Maximise sales and revenue with real-time updates, customizable visuals, and seemless collaboration.
                    Get Started now.</p>
                <a (click)="redirectTo('subscriber-login')">Try it now</a>
            </div>
            <div class="col-12 col-md-6 order-md-2" #firstHorizontalImage>
                <img src="../../../../../assets/images/home/chart.png" alt="">
            </div>
        </div>

        <div class="row horizontal-card align-items-center">
            <div class="col-12 col-md-6 order-md-2">
                <h3 class="col-12">Instant Invoicing</h3>
                <hr class="horizontal-card-hr">
                <p class="fw-bolder">Simplify and streamline your billing process with our convenient multiple window feature. Manage and
                    track multiple invoices with ease.</p>
                <a (click)="redirectTo('subscriber-login')">Get Started now.</a>
            </div>
            <div class="col-12 col-md-6 order-md-1" #secondHorizontalImage>
                <img src="../../../../../assets/images/home/instant-invoicing.png" alt="">
            </div>
        </div>

        <div class="row horizontal-card align-items-center">
            <div class="col-12 col-md-6 order-md-1">
                <h3 class="col-12">Recent Activity Updates</h3>
                <hr class="horizontal-card-hr">
                <p class="fw-bolder">Stay informed with our Recent Activity section. Track new sales, invoices, and important
                    transactions. Take control of your business today!</p>
                <a (click)="redirectTo('subscriber-login')">Take control today!</a>
            </div>
            <div class="col-12 col-md-6 order-md-2">
                <img src="../../../../../assets/images/home/activity-cards.png" alt="">
            </div>
        </div>

        <div class="row horizontal-card align-items-center">
            <div class="col-12 col-md-6 order-md-2">
                <h3 class="col-12">Built in calculators</h3>
                <hr class="horizontal-card-hr">
                <p class="fw-bolder">Simplify billing with integrated calculators for purchase, vouchers, and sales. Streamline operations
                    and generate accurate invoices effortlessly. </p>
                <a (click)="redirectTo('subscriber-login')">Take Control Now!</a>
            </div>
            <div class="col-12 col-md-6 order-md-1" #thirdHorizontalImage>
                <img src="../../../../../assets/images/home/calculator.png" alt="">
            </div>
        </div>

        <div class="row horizontal-card align-items-center">
            <div class="col-12 col-md-6 order-md-1">
                <h3 class="col-12">Report Insights</h3>
                <hr class="horizontal-card-hr">
                <p class="fw-bolder">Unlock valuable insights to optimize performance and enhance experiences. Get Started now!</p>
                <a (click)="redirectTo('subscriber-login')">Get Started</a>
            </div>
            <div class="col-12 col-md-6 order-md-2" #fourthHorizontalImage>
                <img src="../../../../../assets/images/home/report-insights.png" alt="">
            </div>
        </div>

        <div class="row horizontal-card align-items-center">
            <div class="col-12 col-md-6 order-md-2">
                <h3 class="col-12">Seemless Finances</h3>
                <hr class="horizontal-card-hr">
                <p class="fw-bolder">Efficiently manage your finances from accounting to balanced sheets. Simplify tasks and gain accurate
                    insights.</p>
                <a (click)="redirectTo('subscriber-login')">Try it now</a>
            </div>
            <div class="col-12 col-md-6 order-md-1">
                <img src="../../../../../assets/images/home/salesInvoiceScreenshot.png" alt="">
            </div>
        </div>
    </div>
</div>

<app-web-footer></app-web-footer>
