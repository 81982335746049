<div class="main-div">

  <div class="logo-container">
    <img src="../../../../../assets/images/logo.png" alt="A-Square ERP logo image">
  </div>
  <div class="thank-you-section">
          <div class="illustration">
      <img src="../../../../../assets/images/enquiry/thank-you-page/A serene, high-resolution 4K illustration for a professional ERP solutions company. The scene shows a friendly, welcoming office environment with warm.avif" alt="Thank you illustration" />
    </div>

    <div class="thank-you-content">
      <h1>Thank You for Reaching Out!</h1>
      <p>

        We appreciate you contacting A-Square ERP Solutions Pvt Ltd.
        Our team has received your message and is already working on providing you with the best possible solution.<br><br>
        
        Whether you need assistance with our ERP services, have a question, or simply want more information, we're here to help.<br><br>
        One of our experts will be in touch with you soon to ensure all your queries are answered promptly.<br><br>
        
        In the meantime, feel free to explore our website for more insights into our services and how we can support your business needs. <br><br>
        
        Thank you for choosing A-Square ERP Solutions Pvt Ltd. We look forward to serving you well.
      </p>
    </div> 
    
    
  </div>
</div>

