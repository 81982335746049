import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LedgerService {
  constructor(private router: Router, private http: HttpClient) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  GetAllParties(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetAllParties`, body, options);
  }

  SearchParties(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/SearchParties`, body, options);
  }

  GetLedgerByID(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetLedgerByID`, body, options);
  }

  GetAddressbyId(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetAddressbyId`, body, options);
  }

  GetAllLedgerGroup(): Observable<any> {
    const body = new HttpParams({ });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetAllLedgerGroup`, body, options);
  }

  GetAllTaxCategory(): Observable<any> {
    const body = new HttpParams({ });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetAllTaxCategory`, body, options);
  }

  GetAllState(): Observable<any> {
    const body = new HttpParams({ });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetAllState`, body, options);
  }

  GetAllLedger(): Observable<any> {
    const body = new HttpParams({ });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetAllLedger`, body, options);
  }

  GetLedgers(): Observable<any> {
    const body = new HttpParams({ });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetLedgers`, body, options);
  }

  InsertLedger(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/InsertLedger`, body, options);
  }

  DeleteLedger(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/DeleteLedger`, body, options);
  }

  UpdateLedger(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/UpdateLedger`, body, options);
  }

  GetAllPayment(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetAllPayment`, body, options);
  }

  GetLedgerfromGroup(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Ledger/GetLedgerfromGroup`, body, options);
  }
}
