<app-web-navbar></app-web-navbar>

<div class="policy-container">

<h1 class="fw-bold mb-1">Privacy Policy</h1>
<div class="decorator mb-4"></div>
<p  class="mb-3">Last updated: December 23, 2023</p>
<p >This privacy policy sets out how <span class="fw-semibold"> A-SQUARE ERP SOLUTIONS PRIVATE LIMITED </span> uses and protects any information that you give <span class="fw-semibold"> A-SQUARE ERP SOLUTIONS PRIVATE LIMITED </span> when you visit their website and/or purchase from them.</p>
<p ><span class="fw-semibold"> A-SQUARE ERP SOLUTIONS PRIVATE LIMITED </span> is commited to insuring that your privacy is protected. Should we ask you to provide certain information by which you could be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.</p>
<p ><span class="fw-semibold"> A-SQUARE ERP SOLUTIONS PRIVATE LIMITED </span> may change this policy from time to time by updating this page. you should check from time to time to check that you adhere to these changes.</p>

<p class="list-headings mt-4">We may collect the following information: </p>

<ul>
    <li>Name</li>
    <li>Contact information including email address</li>
    <li>Demographic information such as postcode, preferences and interests, if required.</li>
    <li>Other information relevant to customer surveys and/or offers.</li>

</ul>

<p class="list-headings mt-4">What we do with the information we gather: </p>
<p >We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:  </p>

<ul>
    <li>Internal record keeping</li>
    <li>We may use the information to improve our products and services.</li>
    <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided. </li>
    <li>From time to time we may also use the information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use this information to customize the website according to your interests.</li>

</ul>
<p >We are commited to insure that your information is secure. In order to prevent Unauthorized access or disclosure we have put in suitable measures. </p>

<h3 class="fw-bold mb-1">How we use cookies</h3>
<div class="decorator mb-4"></div>
<p>A cookie is a small file which is placed in you computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web application to respond to you as an individual. The web application can tailor it's operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
<p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website to tailor it to our customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>

<p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to you computer or any information about you, other than the data that you choose to share with us.</p>
<p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookie if you prefer. This may prevent you from taking full advantage of the website.</p>

<h3 class="fw-bold mb-1">Controlling your personal information</h3>
<div class="decorator mb-4"></div>
<p class="list-headings">You may choose to restrict the collection or use of your personal information in the following ways: </p>

<ul>
    <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.</li>
    <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind by writing to or emailing us at <span class="ghost-link"> adit.kshatriya&#64;gmail.com </span></li>
</ul>
<p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
<p>If you believe any information we are holding on you is incorrect or incomplete, please write to 22, Sahajeevan Colony, College Road, Nashik, MAHARASTRA 422005. or contact us at <span class="ghost-link" href="tel:9422283398"> 9422283398 </span> or <span class="ghost-link"> adit.kshatriya&#64;gmail.com</span> as soon as possible. We will promptly correct any information found to be incorrect. </p>
</div>

<app-web-footer></app-web-footer>
