import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ItemService } from "src/app/services/item.service";
import * as ItemActions from './item.actions';
import { catchError, map, mergeMap, of } from "rxjs";

@Injectable()
export class ItemEffects {
    loadItems$ = createEffect(() => 
        this.actions$.pipe(
            ofType(ItemActions.loadItems),
            mergeMap(() =>
                this.itemService.GetAllItemsWithBarcode().pipe(
                    map(items => ItemActions.loadItemsSuccess({ items })),
                    catchError(error => of(ItemActions.loadItemsFailure({ error: error.message })))
                )
            )
        )
    );

    loadCompanyLedgers$ = createEffect(() => 
        this.actions$.pipe(
            ofType(ItemActions.loadCompanyLedgers),
            mergeMap(() =>
                this.itemService.GetAllLedgerForCompany().pipe(
                    map(itemLedgers => {
                        return ItemActions.loadCompanyLedgersSuccess({ itemLedgers })
                    }),
                    catchError(error => of(ItemActions.loadCompanyLedgersFailure({ error: error.message })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private itemService: ItemService
    ) {}
}