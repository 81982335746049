import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { addLedger, deleteLedger, updateLedger } from '../common/stores/ledger/ledger.actions';
import { addItem, deleteItem, updateItem } from '../common/stores/item/item.actions';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  private hubConnection: HubConnection;

  private itemCompanyLedgerUpdateSubject = new BehaviorSubject<string>(null);

  itemCompanyLedgerUpdates$ = this.itemCompanyLedgerUpdateSubject.asObservable();

  constructor(private store: Store) { }

  public startConnection(companyId: string) {
    let token = sessionStorage.getItem('ctoken')
    this.hubConnection = new HubConnectionBuilder().withUrl(`${environment.ASquareAPIURL}appHub`, {
      accessTokenFactory: () => token
    }).withAutomaticReconnect().build();

    this.hubConnection.start().then(() => {
      console.log('SignalR Connected');
      this.hubConnection.invoke('JoinCompanyGroup', companyId);
    }).catch((err) => console.log('Error while starting SignalR connection: ' + err));

    this.hubConnection.onclose((error) => {
      console.error('SignalR Disconnected:', error);
      this.reconnectSignalR(companyId);
    });

    this.hubConnection.onreconnected(() => {
      console.log('Reconnected to SignalR. Rejoining group:', companyId);
      this.hubConnection.invoke('JoinCompanyGroup', companyId);
    });

    // this.registerHubMethods();
  }

  private reconnectSignalR(companyId: string) {
    setTimeout(() => {
      if (this.checkHubConnection() == false) {
        console.log('Attempting to Reconnect SignalR...');
        this.startConnection(companyId);
      }
    }, 5000); // Retry every 5 seconds
  }

  private registerHubMethods() {
    // Listen for ledger updates
    this.hubConnection.on('ReceiveLedgerAdded', (data: any) => {
      this.store.dispatch(addLedger({ ledger: data }));
    });

    this.hubConnection.on('ReceiveLedgerUpdated', (data: any) => {
      console.log('Ledger updated: ', data);
      this.store.dispatch(updateLedger({ ledger: data }));
    });

    this.hubConnection.on('ReceiveLedgerDeleted', (data: any) => {
      this.store.dispatch(deleteLedger({ ledgerId: data }));
    });

    //Listen for item updates
    this.hubConnection.on('ReceiveItemAdded', (data: any) => {
      this.store.dispatch(addItem({ item: data }));
    });

    this.hubConnection.on('ReceiveItemUpdated', (data: any) => {
      this.store.dispatch(updateItem({ item: data }));
    });

    this.hubConnection.on('ReceiveItemDeleted', (data: any) => {
      this.store.dispatch(deleteItem({ itemId: data }));
    });

    // Listen for item Company Ledger updates
    this.hubConnection.on('ReceiveItemCompanyLedgerUpdate', (message: string) => {
      this.itemCompanyLedgerUpdateSubject.next(message);
    });
  }

  public checkHubConnection() {
    console.log('Hub ConnectionId: ', this.hubConnection?.connectionId);
    return this.hubConnection?.connectionId ? true : false;
  }

  public async waitForConnection() {
    while (!this.hubConnection || this.hubConnection.state !== 'Connected') {
      console.log('Waiting for SignalR Connection...');
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  public stopConnection() {
    this.hubConnection?.stop().then(() => console.log('SignalR Disconnected'));
  }
}
