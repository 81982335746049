import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { SubscriberLoginResp } from '../components/interface/Subscriber/SubscriberLoginResp';
import { CompanyLoginResp } from '../components/interface/Company/CompanyLoginResp';
import { BlogLoginResp } from '../components/interface/Blog/BlogLoginResp';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from './common/encryption.service';
import { isPlatform, Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseURL = environment.ASquareAPIURL;
  
  private blogUserSubject = new BehaviorSubject<BlogLoginResp | null>(null);
  private subscriberUserSubject = new BehaviorSubject<SubscriberLoginResp | null>(null);
  private companyUserSubject = new BehaviorSubject<CompanyLoginResp | null>(null);

  blogUser$ = this.blogUserSubject.asObservable();
  subscriberUser$ = this.subscriberUserSubject.asObservable();
  companyUser$ = this.companyUserSubject.asObservable();

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  private options = { headers: this.headers };

  subscriberPreference: boolean;
  subscriberDetails: SubscriberLoginResp;

  constructor(private router: Router, private http: HttpClient, private cookieService: CookieService,
    private encrypt: EncryptionService,) { }

  blogLogin(model: any) {
    const body = new HttpParams({ fromObject: model });

    return this.http.post<BlogLoginResp>(this.baseURL + 'Auth/BlogLogin', body, this.options).pipe(
      map((response: BlogLoginResp) => {
        if(response) {
          this.setCurrentBlogUser(response);
        }

        return { success: response.success, userID: response.userID };
      })
    );
  }

  subscriberLogin(model: any) {
    const body = new HttpParams({ fromObject: model });
    
    return this.http.post<SubscriberLoginResp>(this.baseURL + 'Auth/SubscriberLogin', body, this.options).pipe(
      map((response: SubscriberLoginResp) => {
        if(response) {
          this.setCurrentSubscriber(response, model.RememberMe);
        }

        return {success: response.success, subscriptionLeft: response.subscriptionLeft, subscriberID: response.subscriberID };
      })
    );
  }

  companyLogin(model: any) {
    const body = new HttpParams({ fromObject: model });

    return this.http.post<CompanyLoginResp>(this.baseURL + 'Auth/CompanyLogin', body, this.options).pipe(
      map((response: CompanyLoginResp) => {
        if(response) {
          this.setCurrentCompanyUser(response);
        }

        return response.success;
      })
    );
  }

  setCurrentBlogUser(blogUser: BlogLoginResp) {
    sessionStorage.setItem('blogUser', JSON.stringify(blogUser));
    sessionStorage.setItem('token', blogUser.token);
    sessionStorage.setItem('UserID', blogUser.userID.toString());
    this.blogUserSubject.next(blogUser);
  }

  async setCurrentSubscriber(subscriber: SubscriberLoginResp, RememberMe = false) {
    if (isPlatform('capacitor')) {
      await Preferences.set({ key: 'subscriber', value: JSON.stringify(subscriber) });
      await Preferences.set({ key: 'token', value: subscriber.token });
      await Preferences.set({ key: 'subscriberID', value: subscriber.subscriberID.toString() });
      await Preferences.set({ key: 'pid', value: subscriber.packageID.toString() });
      await Preferences.set({ key: 'isSubscritionDetails', value: subscriber.isSubscriptionComplete ? 'true' : 'false' });
      await Preferences.set({ key: 'businessType', value: subscriber.businessType });
      if (subscriber.packageID != null && subscriber.packageID != 0) {
        await Preferences.set({ key: 'isPaymentCom', value: '1' });
      }
      else {
        await Preferences.set({ key: 'isPaymentCom', value: '0' });
      }
    } else {
      sessionStorage.setItem('subscriber', JSON.stringify(subscriber));
      sessionStorage.setItem('token', subscriber.token);
      sessionStorage.setItem("subscriberID", subscriber.subscriberID.toString());
      sessionStorage.setItem("pid", subscriber.packageID.toString());
      sessionStorage.setItem("isSubscritionDetails", subscriber.isSubscriptionComplete ? 'true' : 'false');
      sessionStorage.setItem("businessType", subscriber.businessType);
      if (subscriber.packageID != null && subscriber.packageID != 0) {
        sessionStorage.setItem("isPaymentCom", "1");
      }
      else {
        sessionStorage.setItem("isPaymentCom", "0");
      }
    }

    if (RememberMe == true) {
      if (isPlatform('capacitor')) {
        console.log('Subscriber Service: ' + JSON.stringify(subscriber));
        await Preferences.set({ key: 'subscriber', value: JSON.stringify(subscriber) });
        await Preferences.set({ key: 'token', value: subscriber.token });
        await Preferences.set({ key: 'subscriberID', value: subscriber.subscriberID.toString() });
        await Preferences.set({ key: 'pid', value: subscriber.packageID.toString() });
        await Preferences.set({ key: 'isSubscritionDetails', value: subscriber.isSubscriptionComplete ? 'true' : 'false' });
        await Preferences.set({ key: 'businessType', value: subscriber.businessType });
        if (subscriber.packageID != null && subscriber.packageID != 0) {
          await Preferences.set({ key: 'isPaymentCom', value: '1' });
        }
        else {
          await Preferences.set({ key: 'isPaymentCom', value: '0' });
        }
      } else {
        const myDate = new Date();
        myDate.setMonth(myDate.getMonth() + 6);
        this.cookieService.set('subscriber', JSON.stringify(subscriber), myDate);
        this.cookieService.set('token', subscriber.token, myDate);
        this.cookieService.set('subscriberID', subscriber.subscriberID.toString(), myDate)
        this.cookieService.set('pid', subscriber.packageID.toString(), myDate)
        this.cookieService.set('isSubscritionDetails', subscriber.isSubscriptionComplete ? 'true' : 'false', myDate)
        this.cookieService.set('businessType', subscriber.businessType, myDate)
        if (subscriber.packageID != null && subscriber.packageID != 0) {
          this.cookieService.set("isPaymentCom", "1", myDate);
        }
        else {
          this.cookieService.set("isPaymentCom", "0", myDate);
        }
      }
    }

    this.subscriberUserSubject.next(subscriber);
  }

  setCurrentCompanyUser(companyUser: CompanyLoginResp) {
    sessionStorage.setItem('companyUser', JSON.stringify(companyUser));
    sessionStorage.setItem('ctoken', companyUser.token);
    sessionStorage.setItem('RoleID', companyUser.roleID.toString());
    sessionStorage.setItem('daysLeft', companyUser.daysLeft.toString());
    this.companyUserSubject.next(companyUser);
  }

  async hasSubscriberPreference(): Promise<boolean> {
    if (this.subscriberPreference !== undefined) {
      return this.subscriberPreference;
    }

    const result = await Preferences.get({ key: 'subscriber' });
    this.subscriberPreference = !!result.value;
    return this.subscriberPreference;
  }

  async getPreferenceSubscriberDetails(): Promise<SubscriberLoginResp> {
    const result = await Preferences.get({ key: 'subscriber' });
    this.subscriberDetails = result.value ? JSON.parse(result.value) : null;
    return this.subscriberDetails;
  }

  async logout() {
    if(isPlatform('capacitor')) {
      await Preferences.clear();
    } else {
      this.cookieService.deleteAll();
      sessionStorage.clear();
    }
    this.blogUserSubject.next(null);
    this.subscriberUserSubject.next(null);
  }

  logoutCompany() {
    sessionStorage.removeItem('companyUser');
    sessionStorage.removeItem('ctoken');
    sessionStorage.removeItem('RoleID');
    this.companyUserSubject.next(null);
  }

  // setToken(token: string): void {
  //   sessionStorage.setItem('token', token);
  // }

  // getToken(): string | null {
  //   return sessionStorage.getItem('token');
  // }

  // IsLoggedIn() {
  //   return this.getToken() != null;
  // }

  // logout() {
  //   this.router.navigate(
  //     ['login'],
  //     { queryParams: { companyID: sessionStorage.getItem("CompanyID"),companyName:sessionStorage.getItem("CompanyName") } }
  //   );
  //   sessionStorage.removeItem('token');
  //   sessionStorage.removeItem('RoleID');
  // }

  // login(data): Observable<any> {
  //   const body = new HttpParams({ fromObject: data });
  //   const options = { headers: this.headers };
  //   return this.http.post(`${environment.ASquareAPIURL}UserLogin/AuthenticateUser`, body, options);
  // }

  GetFinancialYear(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}UserLogin/GetFinancialYear`, body, options);
  }

}
