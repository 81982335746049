import { createAction, props } from "@ngrx/store";

export const loadItems = createAction('[Items] Load Items');
export const loadItemsSuccess = createAction('[Items] Load Items Success', props<{ items: any[] }>());
export const loadItemsFailure = createAction('[Items] Load Items Failure', props<{ error: string }>());
export const addItem = createAction('[Items] Add Item', props<{ item: any }>());
export const updateItem = createAction('[Items] Update Item', props<{ item: any }>());

export const loadCompanyLedgers = createAction('[Items] Load Company Ledgers');
export const loadCompanyLedgersSuccess = createAction('[Items] Load Company Ledgers Success', props<{ itemLedgers: any[] }>());
export const loadCompanyLedgersFailure = createAction('[Items] Load Company Ledgers Failure', props<{ error: string }>());