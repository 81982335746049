import { createReducer, on } from "@ngrx/store";
import * as ItemActions from './item.actions';

export interface ItemState {
    items: any[];
    itemLedgers: any[];
    error: string | null;
  }
  
  const initialState: ItemState = {
    items: [],
    itemLedgers: [],
    error: null,
  };

  export const itemReducer = createReducer(
    initialState,
    on(ItemActions.loadItemsSuccess, (state, { items }) => ({
      ...state,
      items,
      error: null,
    })),
    on(ItemActions.loadItemsFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(ItemActions.loadCompanyLedgersSuccess, (state, { itemLedgers }) => ({
      ...state,
      itemLedgers,
      error: null,
    })),
    on(ItemActions.loadCompanyLedgersFailure, (state, { error }) => ({
      ...state,
      error,
    }))
  );