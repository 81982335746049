import { createReducer, on } from "@ngrx/store";
import * as LedgerActions from './ledger.actions';

export interface LedgerState {
    ledgers: any[];
    error: string | null;
  }
  
  const initialState: LedgerState = {
    ledgers: [],
    error: null,
  };

  export const ledgerReducer = createReducer(
    initialState,
    on(LedgerActions.loadLedgersSuccess, (state, { ledgers }) => ({
      ...state,
      ledgers,
      error: null,
    })),
    on(LedgerActions.loadLedgersFailure, (state, { error }) => ({
      ...state,
      error,
    }))
  );