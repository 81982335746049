import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, NgForm, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { LogService } from 'src/app/services/common/log.service';
import { environment } from 'src/environments/environment';
import { isPlatform } from '@ionic/angular';
import { SubscriberLoginResp } from '../interface/Subscriber/SubscriberLoginResp';
import { CookieService } from 'ngx-cookie-service';
import { Preferences } from '@capacitor/preferences';
import { SignalrService } from 'src/app/services/signalr.service';
import { Store } from '@ngrx/store';
import * as fromLedger from 'src/app/common/stores/ledger/ledger.selector';
import * as LedgerActions from 'src/app/common/stores/ledger/ledger.actions';
import * as fromItem from 'src/app/common/stores/item/item.selector';
import * as ItemActions from 'src/app/common/stores/item/item.actions';
import { forkJoin, map, of, take } from 'rxjs';

interface FinancialYear {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  ErrorMessage: string;
  loginForm: FormGroup;
  hide = true;
  Username = new FormControl('', [Validators.required, Validators.email]);
  selectedValue: string;
  financialyears: FinancialYear[] = [];
  token: string | undefined;
  companyID = "";
  companyName = "";
  deleteMode = "";
  financialYear = "";
  isDelete = false;
  displayConfirmDelete = 'none';
  subscriberId = null;
  subscriberResp: SubscriberLoginResp;
  subscriberString: string;

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private logService: LogService,
    private cookieService: CookieService,
    private signalRService: SignalrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.handleRouteParams();
    await this.getSubscriberDetails();
    await this.GetFinancialYear();
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      Username: ['', [Validators.required]],
      Password: ['', [Validators.required]],
      FinancialYear: ['', [Validators.required]]
    });
  }

  async handleRouteParams() {
    this.route.queryParamMap.subscribe(async params => {
      if (params.has('deleteMode') && params.has('companyID') && params.has('financialYear')) {
        this.isDelete = true;
        this.deleteMode = params.get('deleteMode');
        this.companyID = params.get('companyID');
        this.financialYear = params.get('financialYear');
      } else if (params.has('deleteMode') && params.has('companyID')) {
        this.isDelete = true;
        this.deleteMode = params.get('deleteMode');
        this.companyID = params.get('companyID');
        this.financialYear = params.get('financialYear');
      } else if (params.has('companyID') && params.has('companyName')) {
        this.isDelete = false;
        this.companyID = params.get('companyID');
        this.companyName = params.get('companyName');

        if(isPlatform('capacitor')) {
          await Preferences.set({ key: 'CompanyName', value: this.companyName });
        } else {
          sessionStorage.setItem('CompanyName', this.companyName);
        }
      }

      if(isPlatform('capacitor')) {
        await Preferences.set({ key: 'CompanyID', value: this.companyID });
      } else {
        sessionStorage.setItem('CompanyID', this.companyID);
      }
    });
  }

  async getSubscriberDetails() {
    if (isPlatform('capacitor')) {
      this.subscriberResp = await this.authService.getPreferenceSubscriberDetails();
    } else {
      this.subscriberString = sessionStorage.getItem('subscriber');
      if (!this.subscriberString) {
        this.subscriberString = this.cookieService.get('subscriber');
      }
      this.subscriberResp = this.subscriberString ? JSON.parse(this.subscriberString) : null;
    }

    this.subscriberId = this.subscriberResp?.subscriberID;
  }

  async GetFinancialYear() {
    try {
      const companyId = isPlatform('capacitor') ? (await Preferences.get({ key: 'CompanyID' })).value : sessionStorage.getItem("CompanyID");
      let formGroup = new FormGroup({
        CompanyID: new FormControl(companyId)
      });

      const result = await this.authService.GetFinancialYear(formGroup.value).toPromise();
      const years = result.financialYear.split(",");
      this.financialyears = years.map(year => ({
        value: year,
        viewValue: year.split("/")[2]
      }));
    } catch (ex) {
      this.logService.InsertExceptionLog(ex, "Login", "GetFinancialYear");
      console.log("Error in GetFinancialYear: ", ex);
    }
  }

  async Userlogin() {
    try {
      if (this.loginForm.valid) {
        const companyId = isPlatform('capacitor') ? (await Preferences.get({ key: 'CompanyID' })).value : sessionStorage.getItem("CompanyID");
        const loginData = {
          ...this.loginForm.value,
          CompanyID: companyId
        };

        const result = await this.authService.companyLogin(loginData).toPromise();
        if (result == 1) {
          sessionStorage.setItem('UsernameEmail', loginData.Username);
          
          if(isPlatform('capacitor')) {
            await Preferences.set({ key: 'FinancialYear', value: loginData.FinancialYear });
          } else {
            sessionStorage.setItem('FinancialYear', loginData.FinancialYear);
          }

          const daysLeft = sessionStorage.getItem('daysLeft');
          if (daysLeft == '-1') {
            this.ErrorMessage = "Subscription has Ended. Please renew your package";
          } else {
            if (this.isDelete) {
              this.openConfirm();
            } else {
              this.router.navigate(['admin']);
            }
          }

          this.signalRService.startConnection(companyId);

          forkJoin([
            of(this.getAllLedgers()),
            of(this.getAllItems()),
            of(this.getAllItemCompanyLedgers())
          ]).subscribe();
        } else {
          this.ErrorMessage = "Invalid Username or Password.";
        }
      }
    } catch (ex) {
      this.logService.InsertExceptionLog(ex, "Login", "Userlogin");
      console.log(ex);
    }
  }
  

  getErrorMessage() {
    if (this.Username.hasError('required')) {
      return 'You must enter a value';
    }

    return this.Username.hasError('Username') ? 'Not a valid Username' : '';
  }

  getAllLedgers() {
    this.store.select(fromLedger.selectAllLedgers(+this.companyID, this.financialYear)).pipe(
      take(1),
      map(ledgers => {
        if (!ledgers || ledgers.length == 0) {
          this.store.dispatch(LedgerActions.loadLedgers());
        }
      })
    ).subscribe();
  }

  getAllItems() {
    this.store.select(fromItem.selectAllItems(+this.companyID, this.financialYear)).pipe(
      take(1),
      map(items => {
        if (!items || items.length == 0) {
          this.store.dispatch(ItemActions.loadItems());
        }
      })
    ).subscribe();
  }

  getAllItemCompanyLedgers() {
    this.store.select(fromItem.selectAllItemCompanyLedgers(+this.companyID, this.financialYear)).pipe(
      take(1),
      map(ledgers => {
        if (!ledgers || ledgers.length == 0) {
          this.store.dispatch(ItemActions.loadCompanyLedgers());
        }
      })
    ).subscribe();
  }

  // GetFinancialYear() {
  //   try {
  //     this.formGroup = new FormGroup({
  //       CompanyID: new FormControl(sessionStorage.getItem("CompanyID"))
  //     });

  //     if (this.formGroup.valid) {
  //       this.authService.GetFinancialYear(this.formGroup.value).subscribe(
  //         (result) => {
  //           const years = result.financialYear.split(",");
  //           for (var i = 0; i < years.length; i++) {
  //             var year: FinancialYear = {
  //               value: years[i],
  //               viewValue: years[i].split("/")[2]
  //             };

  //             this.financialyears.push(year);
  //           }
  //         }, (err: Error) => {
  //           console.log(err.message);
  //         })
  //     }
  //   }
  //   catch (ex) {
  //     this.logService.InsertExceptionLog(ex,"Login","GetFinancialYear");
  //     console.log(ex);
  //   }
  // }

  logout() {
    try {
      // sessionStorage.removeItem("subscriberID");
      // sessionStorage.removeItem('businessType');
      this.authService.logout();
      this.router.navigate(['subscriber-login']);
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex,"Login","logout");
      console.log(ex);
    }
  }

  selectCompany() {
    try {
      this.router.navigate(
        ['view-company'],
        { queryParams: { subscriberID: this.subscriberId } }
      )
    } catch (ex) {
      console.log(ex);
    }
  }

  deleteCompany() {
    if (this.deleteMode === "company") {
      this.deleteCompanyById(this.companyID);
    } else if (this.deleteMode === "financialYear") {
      if (this.financialyears.length === 1) {
        this.deleteCompanyById(this.companyID);
      } else {
        this.deleteCompanyByFY(this.companyID, this.financialYear);
      }
    }
  }

  deleteCompanyById(companyId: string) {
    let formGroup = new FormGroup({
      CompanyID: new FormControl(companyId)
    });
    try {
      this.companyService.DeleteCompany(formGroup.value).subscribe(
        () => {
          this.clearSession();
          this.selectCompany();
        }, (err: Error) => {
          this.clearSession();
          console.log(err.message);
        });
    }
    catch (ex) {
      this.clearSession();
      console.log(ex);
    }
  }

  deleteCompanyByFY(companyId: string, financialYear: string) {
    let formGroup = new FormGroup({
      CompanyID: new FormControl(companyId),
      FinancialYear: new FormControl(financialYear)
    });

    try {
      this.companyService.DeleteCompanyByFY(formGroup.value).subscribe(
        () => {
          let fyStartDate = [];
          for (let i = 0; i < this.financialyears.length; i++) {
            if (this.financialyears[i].value === financialYear) {
              this.financialyears.splice(i, 1);
            } else {
              fyStartDate.push(this.financialyears[i].value);
            }
          }

          this.companyService.GetCompanyByID().subscribe(
            (result) => {
              result.fyStartDate = fyStartDate.join(",");
              this.companyService.UpdateCompany(result).subscribe(
                (result) => {
                  if (result === 1) {
                    this.clearSession();
                    this.selectCompany();
                  }
                }, (err: Error) => {
                  this.clearSession();
                  console.log(err.message);
                });
            }, (err: Error) => {
              this.clearSession();
              console.log(err.message);
            });
        }, (err: Error) => {
          this.clearSession();
          console.log(err.message);
        });
    }
    catch (ex) {
      this.clearSession();
      this.logService.InsertExceptionLog(ex,"Login","selectCompany");
      console.log(ex);
    }
  }

  clearSession() {
    if (this.deleteMode === "company") {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('companyID');
      sessionStorage.removeItem('CompanyName');
      sessionStorage.removeItem('RoleID');
      sessionStorage.removeItem('UsernameEmail');
    } else if (this.deleteMode === "financialYear") {
      sessionStorage.removeItem('token');
    }
  }

  confirmDelete() {
    this.deleteCompany();
  }

  closeConfirm() {
    this.displayConfirmDelete = 'none';
    this.clearSession();
  }

  openConfirm() {
    this.displayConfirmDelete = 'block';
  }

  redirectToForgotPassword() {
    this.router.navigate(
      ['forgot-password']
    );
  }

}
